import React, { useState } from 'react';
import Button from '../../Button';
import Modal from '../../Modal';
import Icon from '../../Icon';
import axios from 'axios';
import { openToast } from '../../../../helpers/toast';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { type GridRenderCellParams } from '@mui/x-data-grid';
import { type Account } from '../../../../types/systemAdmin';

interface Props {
  params: GridRenderCellParams;
  accounts: Account[];
  setAccounts: React.Dispatch<Account[]>;
}

const UpdateServiceAccountSchema = Yup.object().shape({
  path: Yup.string().required('Required'),
  database: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  password: Yup.string().required('Required')
});

const UpdateServiceAccountModal: React.FC<Props> = ({ params, accounts, setAccounts }) => {
  const [showUserLogin, setShowUserLogin] = useState(false);

  const updateServiceAccount = async (values: any): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/setGeotabUserCredentials`;
    const updateServiceAccountParams = {
      path: values.path,
      database: values.database,
      username: values.email,
      password: values.password,
      account_id: params.row.id
    };
    try {
      const updateServiceAccountResponse = await axios.post(url, updateServiceAccountParams);
      if (!updateServiceAccountResponse) {
        throw new Error('Error updating geotab user credentials');
      }
      openToast({
        type: 'success',
        label: t('Geotab user updated'),
        autoClose: 2000,
        theme: 'dark'
      });
      setShowUserLogin(false);
      setAccounts(accounts.map((item: Account): Account => {
        if (item.id === params.row.id) {
          return {
            ...item,
            serviceAccountStatus: ''
          };
        }
        return item;
      }));
    } catch (error: any) {
      console.error(error);
      openToast({
        type: 'error',
        label: t('Error updating Geotab user'),
        autoClose: 2000,
        theme: 'dark'
      });
    }
  };
  const onHide = (): void => {
    setShowUserLogin(false);
  };
  return (
    <>
      <button
        style={{ margin: '0' }}
        className="transition text-custom-blue-normal hover:text-custom-blue-hover outline-none"
        onClick={() => {
          setShowUserLogin(true);
        }}
        type="button"
      >
       Update
      </button>
      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="right-to-bracket"/>
            <p>{t('Update Service Account')}</p>
          </div>
        )}
        show={showUserLogin}
        onHide={onHide}
        maxWidth={600}
        customZIndex={1001}
      >
        <Formik
          enableReinitialize
          initialValues={{
            path: params.row.path || 'my.geotab.com',
            database: params.row.database || '',
            email: params.row.email || '',
            password: ''
          }}
          onSubmit={(values, { setSubmitting }) => {
            void updateServiceAccount(values);
            setTimeout(() => {
              setSubmitting(false);
            }, 400);
          }}
          validationSchema={UpdateServiceAccountSchema}
        >
          {(props: any) => {
            return (
              <form
                onSubmit={props.handleSubmit}
                className="mt-4"
              >
                <div className="flex flex-col gap-4">
                  <div className="text-xs flex flex-col gap-2">
                    <p>{`${t('Update this accounts Geotab User to be up to date with it\'s counter-part on ' +
                      'Geotab.')}`}</p>
                    <p>{`${t('This login should be an account created specifically for BlueArrow Fuel in your ' +
                      'Geotab database.')}`}</p>
                  </div>
                  <div>
                    <label
                      htmlFor="userLoginPath"
                      className="w-full text-xs"
                    >
                      Path
                    </label>
                    <input
                      className={'transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full' +
                        ' h-[36px] text-xs'}
                      type="text"
                      id={`userLoginPath${params.row.id}`}
                      name="path"
                      value={props.values.path}
                      onChange={props.handleChange}
                    />
                    {props.touched.path && props.errors.path
                      ? (
                        <p className="text-error-1 text-xs">{props.errors.path}</p>
                        )
                      : null}
                  </div>
                  <div>
                    <label
                      htmlFor="userLoginDatabase"
                      className="w-full text-xs"
                    >
                      Database
                    </label>
                    <input
                      className={'transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full' +
                        ' h-[36px] text-xs'}
                      type="text"
                      id={`userLoginDatabase${params.row.id}`}
                      name="database"
                      value={props.values.database}
                      onChange={props.handleChange}
                    />
                    {props.touched.database && props.errors.database
                      ? (
                        <p className="text-error-1 text-xs">{props.errors.database}</p>
                        )
                      : null}
                  </div>
                  <div>
                    <label
                      htmlFor="userLoginEmail"
                      className="w-full text-xs"
                    >
                      Username or Email
                    </label>
                    <input
                      className={'transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full' +
                        ' h-[36px] text-xs'}
                      type="default"
                      id={`userLoginEmail${params.row.id}`}
                      name="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      autoComplete="off"
                    />
                    {props.touched.email && props.errors.email
                      ? (
                        <p className="text-error-1 text-xs">{props.errors.email}</p>
                        )
                      : null}
                  </div>
                  <div>
                    <label
                      htmlFor="userLoginPassword"
                      className="w-full text-xs"
                    >
                      Password
                    </label>
                    <input
                      className={'transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full' +
                        ' h-[36px] text-xs'}
                      type="password"
                      id={`userLoginPassword${params.row.id}`}
                      name="password"
                      value={props.values.password}
                      onChange={props.handleChange}
                      autoComplete="off"
                    />
                    {props.touched.password && props.errors.password
                      ? (
                        <p className="text-error-1 text-xs">{props.errors.password}</p>
                        )
                      : null}
                  </div>
                  <div className="flex gap-5">
                    <Button
                      type="submit"
                      disabled={props.isSubmitting}
                    >
                      {t('Update')}
                    </Button>
                    <Button
                      type="button"
                      onClick={onHide}
                    >
                      {t('Cancel')}
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default withTranslation()(UpdateServiceAccountModal);
