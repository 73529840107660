import React, { useState, useEffect, useCallback, type Dispatch, type SetStateAction } from 'react';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import { CircularProgress } from '@mui/material';
import { gasStationIcon, vehicleIcon } from '../../../../helpers/CustomerAssets';

interface Props {
  gasStationX: number;
  setGasStationX: Dispatch<SetStateAction<any>>;
  gasStationY: number;
  setGasStationY: Dispatch<SetStateAction<any>>;
  vehicleLocation: {
    latitude: number,
    longitude: number,
  };
  clickable: boolean | undefined;
}

const TransactionLocation: React.FC<Props> = ({
  gasStationX,
  setGasStationX,
  gasStationY,
  setGasStationY,
  vehicleLocation,
  clickable
}) => {
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string
  });
  const vehicleX = vehicleLocation?.longitude ?? '';
  const vehicleY = vehicleLocation?.latitude ?? '';
  const [gasStation, setGasStation] = useState({ lat: Number(gasStationY), lng: Number(gasStationX) });
  const [vehicle, setVehicle] = useState<google.maps.LatLng | google.maps.LatLngLiteral | null>(null);
  useEffect(() => {
    if (vehicleLocation) {
      setVehicle({
        lat: Number(vehicleLocation.latitude),
        lng: Number(vehicleLocation.longitude)
      });
    }
  }, []);

  const onLoad = useCallback(function callback (map: any) {
    const bounds = new window.google.maps.LatLngBounds();
    if (vehicleLocation.latitude && vehicleLocation.longitude) {
      bounds.extend({
        lat: Number(vehicleLocation.latitude),
        lng: Number(vehicleLocation.longitude)
      });
      bounds.extend(gasStation);
      map.fitBounds(bounds);
    } else {
      bounds.extend(gasStation);
      map.fitBounds(bounds);
    }

    // Set map options to disable the need for holding the Ctrl key to zoom
    map.setOptions({
      gestureHandling: 'greedy'
    });
  },
  [gasStation, vehicleLocation.latitude, vehicleLocation.longitude]
  );

  const addMarker = (event: google.maps.MapMouseEvent): void => {
    if (clickable) {
      if (event.latLng) {
        const newLocation = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        setGasStation(newLocation);
        setGasStationX(newLocation.lng);
        setGasStationY(newLocation.lat);
      }
    }
  };

  return (
    <>
      {!isLoaded
        ? <div className="text-center"><CircularProgress /></div>
        : <GoogleMap
              mapContainerClassName="map"
              onLoad={onLoad}
              onClick={addMarker}
        >
            {gasStation &&
                <Marker
                    key={`${gasStation.lat}_${gasStation.lng}`}
                    zIndex={101}
                    options={{
                      icon: { url: gasStationIcon.value, scaledSize: new google.maps.Size(75, 75) },
                      animation: google.maps.Animation.DROP
                    }}
                    position={gasStation}
                    draggable={false}
                />
            }
          {vehicleX && vehicleY && vehicle &&
            <Marker
            key={'vehicleMarker'}
            options={{
              icon: { url: vehicleIcon.value, scaledSize: new google.maps.Size(75, 75) },
              animation: google.maps.Animation.DROP
            }}
            position={vehicle}
            draggable={false}
            />
          }
        </ GoogleMap>
      }
    </>
  );
};

export default TransactionLocation;
