import React, { useEffect, useState, useRef } from 'react';
import Modal from '../../Modal';
import { Formik } from 'formik';
import Input from '../../Formik/Input';
import Button from '../../Button';
import Icon from '../../Icon';
import axios from 'axios';
import * as yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import { openToast } from '../../../../helpers/toast';
import SearchableDropdown from '../../SearchableDropdown';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import {
  type AccountToFcp,
  type FuelCard,
  type FuelCardEditorMethod,
  type FuelCardProvider,
  type FuelCardSubmissionType,
  type GeotabDriver,
  type GeotabVehicle,
  type PreferredMatchData
} from '../../../../types/fcp';
import { fetchGeotabDrivers, fetchGeotabVehicles } from '../../../../helpers/api';
import { formatOptions } from '../../../../helpers/fcp';
import Checkbox from '../../Formik/Checkbox';
import {
  iconColorMap,
  iconTypeMapping,
  type TransactionAlerts
} from '../../../../helpers/types';

interface FuelCardEditorProps {
  uniqueIdentifierLabel: string;
  activeFuelCard: FuelCard;
  accountToFcpData: AccountToFcp[];
  method: FuelCardEditorMethod;
  onHide: () => void;
  onFuelCardSubmission: (fuelCard: FuelCard, method: FuelCardEditorMethod) => void;
  hasFetchedData: boolean;
}

const FuelCardEditorModal: React.FC<FuelCardEditorProps> = ({
  activeFuelCard,
  accountToFcpData,
  method,
  onHide,
  onFuelCardSubmission,
  hasFetchedData
}) => {
  const initialActiveFuelCard = useRef<FuelCard>({
    ...activeFuelCard,
    fuelCardAlertsToIgnore: {
      id: activeFuelCard.fuelCardAlertsToIgnore.id,
      // Reverse value to represent the enabled alerts
      no_vehicle_location: !activeFuelCard.fuelCardAlertsToIgnore.no_vehicle_location,
      location_mismatch: !activeFuelCard.fuelCardAlertsToIgnore.location_mismatch,
      fuel_level_mismatch: !activeFuelCard.fuelCardAlertsToIgnore.fuel_level_mismatch,
      fuel_type_mismatch: !activeFuelCard.fuelCardAlertsToIgnore.fuel_type_mismatch,
      filling_time_alert: !activeFuelCard.fuelCardAlertsToIgnore.filling_time_alert,
      filling_percent_alert: !activeFuelCard.fuelCardAlertsToIgnore.filling_percent_alert
    }
  });
  const [preferredMatchData, setPreferredMatchData] = useState<PreferredMatchData[]>([]);
  const [uniqueIdentifierLabel, setUniqueIdentifierLabel] = useState<string>('Unique ID');
  const [loading, setLoading] = useState<boolean>(false);

  const fuelCardValidationSchema = yup.object().shape({
    fuelCardProvider: yup.string().required(t('Required')),
    match: yup.object().shape({
      id: yup.string().required(),
      name: yup.string().required()
    }).required(),
    fuelType: yup.string().required(t('Required')),
    uniqueIdentifier: yup.string().required(t('Required'))
  });

  const reverseEnabledAlertRequestParam = (alerts: TransactionAlerts): TransactionAlerts => {
    // Reverse unchanged alerts
    return {
      no_vehicle_location: !alerts.no_vehicle_location,
      location_mismatch: !alerts.location_mismatch,
      fuel_level_mismatch: !alerts.fuel_level_mismatch,
      fuel_type_mismatch: !alerts.fuel_type_mismatch,
      filling_time_alert: !alerts.filling_time_alert,
      filling_percent_alert: !alerts.filling_percent_alert
    };
  };

  const handleFormSubmit = async (fuelCard: FuelCard): Promise<void> => {
    let url: string = `${process.env.REACT_APP_API_URL}/api/fuel-cards`;
    let fuelCardAlertsToIgnoreUrl = `${process.env.REACT_APP_API_URL}/api/fuel-card-alerts-to-ignore`;
    const params: FuelCardSubmissionType = {
      unique_identifier: fuelCard.uniqueIdentifier,
      account_id: fuelCard.accountId,
      fuel_type: fuelCard.fuelType,
      fcp: fuelCard.fuelCardProvider
    };
    if (fuelCard.preferredMatch === 'vehicle') {
      params.geotab_vehicle_id = fuelCard.match.id;
      params.vehicle_id = fuelCard.match.device_id;
      params.driver_resource_id = null;
      // params.driver_id = null; todo once the geotab_drivers table is created;
    } else {
      params.driver_resource_id = fuelCard.match.id;
      // params.driver_id = todo once the geotab_drivers table is created
      params.vehicle_id = null;
      params.geotab_vehicle_id = null;
    }
    try {
      setLoading(true);
      let response;
      let message;
      let { id, ...alerts } = fuelCard.fuelCardAlertsToIgnore as { id: number, } & TransactionAlerts;
      alerts = reverseEnabledAlertRequestParam(alerts);

      if (method === 'edit') {
        url += `/${fuelCard.id}`;
        response = await axios.put(url, params);
        message = 'Fuel card updated';
        fuelCardAlertsToIgnoreUrl += `/${fuelCard.fuelCardAlertsToIgnore.id}`;
        await axios.put(fuelCardAlertsToIgnoreUrl, alerts);
        onFuelCardSubmission({ ...fuelCard, fuelCardAlertsToIgnore: { id: fuelCard.fuelCardAlertsToIgnore.id, ...alerts } }, method);
      } else {
        params.fuel_card_alerts_to_ignore = alerts;
        response = await axios.post(url, params);
        fuelCard.id = response.data.data.fuelCard.id;
        fuelCard.fuelCardAlertsToIgnore.id = response.data.data.fuelCardAlertsToIgnore.id;
        message = 'Fuel card created';
        onFuelCardSubmission({ ...fuelCard, fuelCardAlertsToIgnore: { id: fuelCard.fuelCardAlertsToIgnore.id, ...alerts } }, method);
      }
      if (response?.status >= 200 && response?.status < 300) {
        openToast({
          type: 'success',
          label: t(message),
          autoClose: 2000,
          theme: 'dark'
        });
        onHide();
      } else {
        openToast({
          type: 'error',
          label: t('Error submitting fuel card'),
          autoClose: 2000,
          theme: 'dark'
        });
      }
    } catch (error: any) {
      console.error('Error submitting fuel card: ', error);
      openToast({ type: 'error', label: t('Error submitting fuel card'), autoClose: 2000, theme: 'dark' });
    } finally {
      setLoading(false);
    }
  };

  const fetchPreferredMatchData = async (preferredMatch: string): Promise<PreferredMatchData[] | null> => {
    try {
      setLoading(true);
      const responseData: GeotabVehicle[] | GeotabDriver[] | null = preferredMatch === 'vehicle'
        ? await fetchGeotabVehicles()
        : await fetchGeotabDrivers();
      if (responseData) return responseData.sort((a: any, b: any) => a.name.localeCompare(b.name));
    } catch (error) {
      console.error('Error fetching preferred match data: ', error);
    } finally {
      setLoading(false);
    }
    return null;
  };

  const findAccountToFcp = (fuelCardProvider: FuelCardProvider | null): AccountToFcp | null => {
    for (const accountToFcp of accountToFcpData) {
      if (accountToFcp.fuelCardProvider === fuelCardProvider) {
        return accountToFcp;
      }
    }
    return null;
  };

  const handleProviderChange = async (fuelCardProvider: FuelCardProvider, setFieldValue: (field: string, value: string) => void): Promise<void> => {
    const accountToFcp: AccountToFcp | null = findAccountToFcp(fuelCardProvider);
    if (accountToFcp) {
      setUniqueIdentifierLabel(formatOptions(accountToFcp.uniqueField));
      setFieldValue('preferredMatch', accountToFcp.preferredMatch);
      setPreferredMatchData(await fetchPreferredMatchData(accountToFcp.preferredMatch) ?? []);
    }
  };

  useEffect(() => {
    if (method === 'edit' && activeFuelCard) {
      (async (): Promise<void> => {
        const accountToFcp: AccountToFcp | null = findAccountToFcp(activeFuelCard.fuelCardProvider);
        if (accountToFcp) {
          setUniqueIdentifierLabel(formatOptions(accountToFcp.uniqueField));
        }
        if (activeFuelCard.preferredMatch) {
          setPreferredMatchData(await fetchPreferredMatchData(activeFuelCard.preferredMatch) ?? []);
        }
      })();
    }
  }, [activeFuelCard]);

  return (
    <>
      <Modal
        header={
          <div className="flex items-center gap-x-2">
            <Icon name={method === 'edit' ? 'edit' : 'plus'}/>
            <p>{method === 'edit' ? t('Edit Card') : t('Add New Card')}</p>
          </div>
        }
        show={true}
        onHide={() => {
          onHide();
        }}
      >
        {hasFetchedData && (
          <Formik
            enableReinitialize
            initialValues={initialActiveFuelCard.current}
            onSubmit={(values: FuelCard, { setSubmitting }) => {
              handleFormSubmit(values).then(() => {
                setSubmitting(false);
              });
            }}
            validationSchema={fuelCardValidationSchema}
          >
            {(props: any) => {
              return (
                <form
                  onSubmit={props.handleSubmit}
                  className="mt-4"
                >
                  {loading && (
                    <div
                      className="w-full h-full absolute inset-0 flex justify-center items-center z-[1000] bg-opacity-20 bg-black"
                    >
                      <CircularProgress/>
                    </div>
                  )}
                  <div className="flex flex-col gap-y-6">
                    <Input
                      name="fuelCardProvider"
                      type="select"
                      options={accountToFcpData.length ? accountToFcpData.map((fcp: AccountToFcp) => fcp.fuelCardProvider) : []}
                      onChange={(option: FuelCardProvider): void => {
                        void handleProviderChange(option, props.setFieldValue);
                      }}
                      label={t('Provider')}
                      defaultValue={props.values?.fuelCardProvider?.toUpperCase()}
                    />
                    {(method === 'edit' || (props.values.fuelCardProvider && preferredMatchData.length > 0)) &&
                        <div className="flex flex-col gap-y-2">
                            <label
                                htmlFor="match"
                                className="w-full text-xs"
                            >
                              {props.values.preferredMatch === 'vehicle' ? t('Vehicle') : t('Driver')}
                            </label>
                            <SearchableDropdown
                                name="match"
                                options={preferredMatchData.map((item: PreferredMatchData) => ({
                                  value: item.id,
                                  label: item.name
                                }))}
                                selectedVal={props.values.match.id}
                                handleChange={(value: string | number) => {
                                  const match: PreferredMatchData | undefined = preferredMatchData.find((item: PreferredMatchData) => String(item.id) === String(value));
                                  if (match) {
                                    props.setFieldValue('match', match);
                                  }
                                }}
                            />
                          {props.errors.match && props.touched.match &&
                              <p className="text-red-600 text-xs">{t('Required')}</p>}
                        </div>}
                    <Input
                      name="fuelType"
                      type="select"
                      options={[t('Regular'), t('Midgrade'), t('Premium'), t('Super'), t('Diesel'), t('E85'), t('CNG'), t('LPG'), t('Other'), t('Ignore')]}
                      label={t('Fuel Type')}
                      placeholder={`${t('Select')}...`}
                      defaultValue={props.values.fuelType}
                    />
                    <Input
                      name="uniqueIdentifier"
                      type="default"
                      label={t(uniqueIdentifierLabel)}
                      defaultValue={props.values.uniqueIdentifier}
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 mt-6">
                    <div className="flex items-center gap-x-2">
                      <h3>{t('Enabled Alerts')}</h3>
                    </div>
                    <Checkbox
                      switchType
                      name="fuelCardAlertsToIgnore.no_vehicle_location"
                      label={
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.no_vehicle_location]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.no_vehicle_location}/>
                          </div>
                          <p>{t('No Vehicle Location')}</p>
                        </div>
                      }
                    />
                    <Checkbox
                      switchType
                      name="fuelCardAlertsToIgnore.location_mismatch"
                      label={
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.location_mismatch]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.location_mismatch}/>
                          </div>
                          <p>{t('Location Mismatch')}</p>
                        </div>
                      }
                    />
                    <Checkbox
                      switchType
                      name="fuelCardAlertsToIgnore.fuel_level_mismatch"
                      label={
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.fuel_level_mismatch]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.fuel_level_mismatch}/>
                          </div>
                          <p>{t('Fuel Level Mismatch')}</p>
                        </div>
                      }
                    />
                    <Checkbox
                      switchType
                      name="fuelCardAlertsToIgnore.fuel_type_mismatch"
                      label={
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.fuel_type_mismatch]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.fuel_type_mismatch}/>
                          </div>
                          <p>{t('Fuel Type Mismatch')}</p>
                        </div>
                      }
                    />
                    <Checkbox
                      switchType
                      name="fuelCardAlertsToIgnore.filling_time_alert"
                      label={
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.filling_time_alert]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.filling_time_alert}/>
                          </div>
                          <p>{t('Filling Time Alert')}</p>
                        </div>
                      }
                    />
                    <Checkbox
                      switchType
                      name="fuelCardAlertsToIgnore.filling_percent_alert"
                      label={
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.filling_percent_alert]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.filling_percent_alert}/>
                          </div>
                          <p>{t('Filling Percent Alert')}</p>
                        </div>
                      }
                    />
                  </div>
                  <Button
                    type="submit"
                    className="!px-12"
                    disabled={props.isSubmitting}
                  >
                    {t('Submit')}
                  </Button>
                </form>
              );
            }}
          </Formik>
        )}
      </Modal>
    </>
  );
};

export default withTranslation()(FuelCardEditorModal);
