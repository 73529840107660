import React, { useState } from 'react';
import Modal from '../Modal';
import IconButton from '../IconButton';
import Button from '../Button';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import Icon from '../Icon';

interface Props {
  onDelete: () => void;
  header: string;
  message: string;
  button?: boolean;
  disabled?: boolean;
}

const DeleteItemModal: React.FC<Props> = ({
  onDelete,
  header,
  message,
  button = false,
  disabled = false
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const onHide = (): void => {
    setIsShown(false);
  };

  const handleDelete = (): void => {
    onDelete();
    onHide();
  };

  return (
        <>
          {button
            ? <Button
            disabled={disabled}
            type="button"
            outline
            className="!px-12"
            onClick={() => {
              setIsShown(true);
            }}
            >
            {t('Delete')}
          </Button>
            : <IconButton
            icon="trash"
            onClick={() => {
              setIsShown(true);
            }}
            />}

            <Modal
                show={isShown}
                onHide={onHide}
                maxWidth={400}
                customZIndex={1002}
                header={
                  <div className="flex items-center gap-x-2">
                    <Icon name="trash" />
                    <p>{t(header)}</p>
                  </div>
                }
            >
                <p className="text-left text-sm mt-4">{t(message)}</p>
                <div className="flex items-center justify-left gap-x-2">
                    <Button
                        type="button"
                        onClick={onHide}
                        variant="link"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        outline
                        variant="danger"
                        type="button"
                        onClick={handleDelete}
                    >
                        {t('Delete')}
                    </Button>
                </div>
            </Modal>
        </>
  );
};

export default withTranslation()(DeleteItemModal);
