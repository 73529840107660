import React, { useState, type SetStateAction, type Dispatch, useEffect } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Button from '../../Button';
import { Formik, type FormikProps, type FormikHelpers } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { toast } from 'react-toastify';
import SearchableDropdown from '../../SearchableDropdown';
import { useVehicleData } from '../../../../hooks/useVehicleData';
import { type GeotabVehicle } from '../../../../types/fcp';
import {
  type TransformedTransaction
} from '../../../../types/transactions';

import { openToast } from '../../../../helpers/toast';

interface FormValues {
  geotab_vehicle_id: number;
  geotab_vehicle_name: string;
}

interface VehicleOption {
  label: string;
  value: number;
}

const findMatchingGeotabVehicle = (matchDeviceId: string | null, geotabVehicles: GeotabVehicle[]): GeotabVehicle | null => {
  if (!matchDeviceId) return null;
  return geotabVehicles.find((geotabVehicle: GeotabVehicle) => geotabVehicle.device_id.toLowerCase() === matchDeviceId.toLowerCase()) ?? {
    id: -1,
    device_id: '',
    name: ''
  };
};

interface Props {
  vehicleId: string | null;
  currentTransactionId: number;
  currentTransactionGroupId: number | null;
  currentTransactionKind: 'transaction' | 'transactionGroup';
  timestamp: number;
  setTransactionDetailsData: Dispatch<SetStateAction<TransformedTransaction>>;
  removedDismissedTransaction: (id: number, transactions?: any) => void;
}

const ReassignTransaction: React.FC<Props> = ({
  currentTransactionId,
  timestamp,
  vehicleId
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    geotab_vehicle_id: -1,
    geotab_vehicle_name: ''
  });
  const [vehicleOptions, setVehicleOptions] = useState<VehicleOption[]>([{
    label: '',
    value: -1
  }]);
  const geotabVehicles: GeotabVehicle[] = useVehicleData();

  useEffect(() => {
    setVehicleOptions(geotabVehicles.map((vehicle: GeotabVehicle): VehicleOption => ({
      value: vehicle.id,
      label: vehicle.name
    })));
    const matchingGeotabVehicle = findMatchingGeotabVehicle(vehicleId, geotabVehicles);
    setInitialFormValues({
      geotab_vehicle_id: matchingGeotabVehicle ? matchingGeotabVehicle.id : -1,
      geotab_vehicle_name: matchingGeotabVehicle ? matchingGeotabVehicle.name : ''
    });
  }, [geotabVehicles]);

  const reassignTransaction = async (values: FormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
    const oldGeotabVehicle = geotabVehicles.find((vehicle: GeotabVehicle) => vehicle.device_id === vehicleId);
    const oldGeotabVehicleId = oldGeotabVehicle?.id;
    if (values.geotab_vehicle_id === oldGeotabVehicleId) {
      actions.setSubmitting(false);
      reassignErrorNotification('Please select a different vehicle');
    } else if (values.geotab_vehicle_id === -1) {
      actions.setSubmitting(false);
      reassignErrorNotification('Please select a vehicle');
    } else {
      const params = {
        vehicle_id: values.geotab_vehicle_id,
        fuel_transaction_id: Number(currentTransactionId),
        timestamp: Number(timestamp)
      };
      try {
        setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/api/reassign-ifta-transaction`;
        const response = await axios.post(url, params);
        if (response.data.data) {
          openToast({
            type: 'success',
            label: 'Transaction is being reprocessed, come back later to view the updated transaction',
            autoClose: 5000,
            theme: 'dark'
          });
          setIsShown(false);
        } else {
          console.error('Reassign transaction response is invalid: ', response);
          reassignErrorNotification('Error reassigning vehicle');
        }
      } catch (error: any) {
        console.error(error);
        actions.setSubmitting(false);
        reassignErrorNotification('Error reassigning vehicle');
      } finally {
        setLoading(false);
      }
    }
  };

  const reassignNotification = (): void => {
    toast.success('Transaction reassigned', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'dark',
      toastId: 'reassign-success'
    });
  };

  const reassignErrorNotification = (message: string): void => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'dark',
      toastId: 'reassign-error'
    });
  };

  return (
    <>
      <Button
        outline
        className="!mt-0 !px-4 flex items-center gap-x-1"
        type="button"
        onClick={() => {
          setIsShown(true);
        }}
      >
        <Icon name="arrow-up-from-arc"/>
        Reassign transaction
      </Button>
      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="arrow-up-from-arc"/>
            <p>Reassign transaction</p>
          </div>
        )}
        show={isShown}
        onHide={() => {
          setIsShown(false);
        }}
        customZIndex={1001}
      >
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          onSubmit={reassignTransaction}
        >
          {(props: FormikProps<FormValues>) => {
            return (
              <form
                onSubmit={props.handleSubmit}
                className="mt-4"
              >
                <div className="flex flex-col gap-y-6">
                  {!geotabVehicles?.length
                    ? <div className="flex align-middle items-center justify-center">
                      <CircularProgress/>
                    </div>
                    : <>
                      <div>
                        <label
                          htmlFor="vehicle"
                          className="w-full text-xs"
                        >
                          Vehicle
                        </label>
                        <SearchableDropdown
                          name="geotabVehicle"
                          options={vehicleOptions}
                          selectedVal={props.values.geotab_vehicle_id}
                          handleChange={(value: string, label: string) => {
                            props.setFieldValue('geotab_vehicle_id', Number(value));
                            props.setFieldValue('geotab_vehicle_name', label);
                          }}
                        />
                      </div>
                      {loading && (
                        <div
                          className="w-full h-full absolute inset-0 flex justify-center items-center z-[1000] bg-opacity-20 bg-black"
                        >
                          <CircularProgress/>
                        </div>
                      )}
                    </>}
                </div>
                <div className="flex justify-center">
                  <Button
                    disabled={props.isSubmitting}
                    className="!px-12"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default ReassignTransaction;
