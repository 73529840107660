import React, { useState } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Button from '../../Button';
import Input from '../../Formik/Input';
import { Formik } from 'formik';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { openToast } from '../../../../helpers/toast';
import ShareToolTip from './ShareToolTip';
import { t } from 'i18next';
import {
  type TransformedTransaction,
  type TransformedTransactionGroup
} from '../../../../types/transactions';

interface Props {
  callback: (state: boolean) => void;
  transactionData?: TransformedTransaction | TransformedTransactionGroup;
  group?: boolean;
  transactionGroup?: any;
}

const TransactionDetailsShareModal: React.FC<Props> = ({ callback, transactionData, group = false, transactionGroup }) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const sendEmail = (): void => {
    setError(false);
    let valid = true;
    if (email) {
      valid = validateEmail(email);
      if (!valid) {
        setError(true);
        openToast({ type: 'error', label: 'Invalid email', autoClose: 2000, theme: 'dark' });
      } else {
        void (async () => {
          setLoading(true);

          if (group) {
            const params = {
              email,
              message,
              transaction_group_id: transactionGroup.id
            };
            try {
              const url = `${process.env.REACT_APP_API_URL}/api/send-group-bad-transaction-email`;
              const response = await axios.post(url, params);
              console.log(response);
              openToast({ type: 'success', label: 'Email sent', autoClose: 2000, theme: 'dark' });
              setIsShown(false);
              callback(true);
              setLoading(false);
            } catch (err) {
              setError(true);
              openToast({ type: 'error', label: 'Error sending email', autoClose: 2000, theme: 'dark' });
              console.log(err);
              setLoading(false);
            }
          } else {
            const params = {
              email,
              message,
              transaction_id: transactionData?.id,
              vehicle_name: transactionData?.vehicleName
            };
            try {
              const url = `${process.env.REACT_APP_API_URL}/api/send-single-bad-transaction-email`;
              const response = await axios.post(url, params);
              console.log(response);
              openToast({ type: 'success', label: 'Email sent', autoClose: 2000, theme: 'dark' });
              setIsShown(false);
              callback(true);
              setLoading(false);
            } catch (err) {
              setError(true);
              openToast({ type: 'error', label: 'Error sending email', autoClose: 2000, theme: 'dark' });
              console.log(err);
              setLoading(false);
            }
          }
        })();
      }
    } else {
      setError(true);
      openToast({ type: 'error', label: 'Missing fields', autoClose: 2000, theme: 'dark' });
    }
  };

  const validateEmail = (email: string): boolean => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  return (
    <>
      <Button
        outline
        className="!mt-0 !px-4 flex items-center gap-x-1"
        type="button"
        onClick={() => {
          setIsShown(true);
        }}
      >
        <Icon name="share" />
        {t('Share Transaction')}
      </Button>
      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="share" />
            <p>{t('Share Transaction')}</p>
            <ShareToolTip />
          </div>
        )}
        show={isShown}
        onHide={() => {
          setIsShown(false);
        }}
        customZIndex={1001}
        maxWidth={'40rem'}
      >
        <Formik
          enableReinitialize
          initialValues={{ email: '' }}
          onSubmit={(values, { setSubmitting }) => {
            sendEmail();
            setTimeout(() => {
              setSubmitting(false);
            }, 400);
          }}
        >
          {(props: any) => {
            return (
              <form
                onSubmit={props.handleSubmit}
                className="mt-4"
              >
                <div className="flex flex-col gap-y-6">
                  <div className="flex flex-col gap-y-1">
                    <Input
                      name="email"
                      type="text"
                      label={`${t('Email Address *')}`}
                      placeholder={`${t('Enter Email (separate multiple emails with commas')}`}
                      setState={setEmail}
                      stateValue={email}
                    />
                    {error && <p className="text-xs text-error-3">Hover <Icon name="circle-info" /> for help</p>}
                  </div>
                  <Input
                    name="message"
                    type="textarea"
                    label={`${t('Message *')}`}
                    placeholder={`${t('Enter Message')}`}
                    setState={setMessage}
                    stateValue={message}
                  />
                </div>
                <div className="flex justify-left">
                  <Button
                    disabled={props.isSubmitting}
                    className="!px-12"
                  >
                    {t('Share')}
                  </Button>
                </div>
                {isLoading && <div className="flex justify-center mt-3">
                    <CircularProgress />
                </div>}
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default TransactionDetailsShareModal;
