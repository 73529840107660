import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import axios, { AxiosError } from 'axios';
import { Formik, type FormikProps } from 'formik';
import Input from '../../../ui/shared/Formik/Input';
import Button from '../../../ui/shared/Button';
import { openToast } from '../../../helpers/toast';
import { CircularProgress } from '@mui/material';
import * as Yup from 'yup';

interface Values {
  password: string;
}

interface GeotabUser {
  id: string;
  database: string;
  password: string;
  username: string;
  path: string;
  user_id: string;
}

const defaultGeotabUserValue: GeotabUser = {
  id: '',
  database: '',
  username: '',
  password: '',
  path: '',
  user_id: ''
};

const UserAccess: React.FC = () => {
  const [geotabUser, setGeotabUser] = useState<GeotabUser>(defaultGeotabUserValue);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const credentialSchema = Yup.object().shape({
    password: Yup.string().required('Required')
  });

  useEffect(() => {
    const geotabUserCredentialsString = localStorage.getItem('geotabAPI_credentials');
    if (geotabUserCredentialsString) {
      const geotabUserCredentials = JSON.parse(geotabUserCredentialsString);
      const fetchGeotabUser = async (): Promise<void> => {
        try {
          const url: string = `${process.env.REACT_APP_API_URL}/api/get-geotab-user-with-credentials`;
          const geotabUserResponse = await axios.post(url, geotabUserCredentials);
          if (geotabUserResponse) {
            setGeotabUser(geotabUserResponse.data.data);
          } else {
            setGeotabUser(defaultGeotabUserValue);
          }
          // also add vehicle groups
        } catch (error) {
          console.log('Error fetching geotab users: ', error);
        }
      };
      void fetchGeotabUser();
    }
  }, []);

  const errorNotification = (message: string): void => {
    openToast({
      type: 'error',
      label: message,
      autoClose: 5000,
      theme: 'dark'
    });
  };

  const updateGeotabUserPassword = async (formikValues: any): Promise<void> => {
    setLoading(true);
    if (!geotabUser.database) {
      errorNotification('Geotab user is missing database');
      return;
    }
    try {
      if (!geotabUser || !formikValues) {
        errorNotification('Invalid Geotab user');
        return;
      }
      const params: { database: string, password: string, username: string, path: string, } = {
        database: geotabUser?.database,
        username: geotabUser?.username,
        password: formikValues.password,
        path: geotabUser?.path
      };
      await axios.post(`${process.env.REACT_APP_API_URL}/api/authenticateGeotabUser`, params);
      const updatedGeotabUser = {
        id: geotabUser.id,
        database: geotabUser.database,
        username: geotabUser.username,
        password: formikValues.password,
        path: geotabUser.path
      };
      try {
        const geotabUserResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/update-geotab-user`, updatedGeotabUser);
        const account = { id: geotabUserResponse.data.data.account_id }; // Only need account id for creating a GeotabService for the user on the backend
        await axios.post(`${process.env.REACT_APP_API_URL}/api/update-geotab-user-info`, { geotabUser: geotabUserResponse.data.data, account: account });
      } catch (err) {
        console.log(err);
      }

      // On success
      window.location.href = '/';
      setIsSubmitting(false);
    } catch (error: any) {
      console.error(error);
      if (error instanceof AxiosError && error?.response && error.response?.status) {
        // Unprocessable content
        if (error.response.status === 422) {
          errorNotification('Invalid input');
          // Max authentication attempts made to the Geotab server
        } else if (error.response.status === 503) {
          errorNotification('Exceeded max attempts, try again later');
          // Invalid password
        } else if (error.response.status === 400) {
          errorNotification('Authentication failed, please enter the password for your current geotab user');
        } else {
          // Unknown cause
          errorNotification('An error occurred, please try again later');
        }
      } else {
        errorNotification('Error setting Geotab credentials');
      }
      setIsSubmitting(false);
    }
  };
  const handleSubmit = (submittedValues: any): void => {
    updateGeotabUserPassword(submittedValues).then(() => {
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.log(error);
    });
  };

  return (
      <div className="flex justify-center items-center max-w-[450px]">
        {loading && (
            <div className="w-full h-full absolute inset-0 flex justify-center items-center z-[1000] bg-opacity-20 bg-transparent">
              <CircularProgress />
            </div>
        )}
        <div className="flex flex-col gap-8">
          <div className="text-base break-words">
            Due to a recent update, we need you to enter your Geotab password to enable some new features.
            You only need to enter it once, after that, you will access the add-in like normal. Thanks!
          </div>
          <Formik
              validationSchema={credentialSchema}
              initialValues={{ password: '' }}
              onSubmit={handleSubmit}
          >
            {(props: FormikProps<Values>) => (
                <form onSubmit={props.handleSubmit}>
                  <div className="flex flex-col gap-y-6">
                    <Input
                        name="password"
                        type="password"
                        label="Geotab User Password"
                    />
                  </div>
                  <Button
                      disabled={isSubmitting}
                      className="w-full"
                  >
                    Set Password
                  </Button>
                </form>
            )}
          </Formik>
        </div>
      </div>
  );
};

export default withTranslation()(UserAccess);
