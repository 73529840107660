import React from 'react';
import { refreshLogin } from '../../../index';
import Button from '../../../ui/shared/Button';

const UnauthorizedAccess: React.FC = () => {
  return (
    <div className="w-full -my-4 mb-1 grid place-content-center place-items-center text-custom-gray-main">
      <p className="text-[4rem] leading-[8rem]">401</p>
      <p className="text-lg">You are not authorized to view this page</p>
      <div className="flex justify-center w-full">
        <Button onClick={refreshLogin}>
          Retry
        </Button>
      </div>
    </div>
  );
};

export default UnauthorizedAccess;
