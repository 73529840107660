import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { CircularProgress, type Theme } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { DataGrid, type GridInitialState, GridToolbar, type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid';
import axios from 'axios';
import Icon from '../../../ui/shared/Icon';
import IconButton from '../../../ui/shared/IconButton';
import ReportModal from '../../../ui/shared/Modals/reports/Report.modal';
import { appState, useSignal, handleDataGridStateChange } from '../../../index';
import { openToast } from '../../../helpers/toast';
import { type Report } from '../../../types/reports';
import { fetchGeotabVehicles } from '../../../helpers/api';

const theme: Theme = createTheme({
  palette: {
    mode: 'dark'
  }
});

interface PaginationModel {
  page: number;
  pageSize: number;
}

const transformReport = (reports: any): Report[] => {
  return reports.map((report: any): Report => {
    return {
      accountId: report.account_id,
      alerts: report.alerts,
      day: report.day,
      email: report.email,
      id: report.id,
      interval: report.interval,
      selectedIds: report.selected_ids,
      time: report.time,
      timezone: report.timezone,
      type: report.type,
      vehicleGroups: report.vehicle_groups
    };
  });
};
const Reports: React.FC = () => {
  const updatedAccountId = useSignal(appState.accountId);
  const [scheduledReports, setScheduledReports] = useState<Report[]>([]);
  const [existingScheduledReport, setExistingScheduledReport] = useState<Report | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isScheduledReportModalOpen, setIsScheduledReportModalOpen] = useState<boolean>(false);
  const [isRunReportModalOpen, setIsRunReportModalOpen] = useState<boolean>(false);
  const [isEditScheduledReportModalOpen, setIsEditScheduledReportModalOpen] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(0);
  const [rowCountState, setRowCountState] = useState<number>(rowCount);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 50
  });

  const fetchScheduledReports = async (model: PaginationModel): Promise<void> => {
    setLoading(true);
    const { page, pageSize } = model;
    const url: string = `${process.env.REACT_APP_API_URL}/api/paginated-report-schedules` +
      `?filter=account_id:${appState.accountId.value}(eq)&page=${page + 1}&pageSize=${pageSize}`;
    try {
      const response = await axios.get(url);
      setScheduledReports(transformReport(response.data.data));
      setRowCount(response.data.meta.total);
    } catch (err: any) {
      if (err?.response?.status === 429) {
        console.log('Too many requests', err);
        openToast({
          type: 'error',
          label: t('Too many requests, try again in a few minutes'),
          autoClose: 2000,
          theme: 'dark'
        });
      } else {
        console.log(err);
        openToast({
          type: 'error',
          label: t('Error fetching reports'),
          autoClose: 2000,
          theme: 'dark'
        });
      }
    }
    setLoading(false);
  };

  const formatIntervalToDate = (today: Date, report: any): Date => {
    switch (report.interval) {
      case 'monthly':
        today.setDate(today.getDate() - 28);
        return today;
      case 'biweekly':
        today.setDate(today.getDate() - 14);
        return today;
      case 'weekly':
        today.setDate(today.getDate() - 7);
        return today;
      default:
        return today;
    }
  };
  const getGeotabVehicles = async (): Promise<any> => {
    try {
      const response = await fetchGeotabVehicles();
      return response;
    } catch (error) {
      console.log('Error fetching geotab vehicles:', error);
    }
  };

  // todo() use the new run scheduled reports route once finished
  // const runScheduledReport = async (report: any): Promise<void> => {
  //   const url: string = `${process.env.REACT_APP_API_URL}/api/run-scheduled-report`;
  //   const params = {
  //     account_id: accountId.value,
  //     report_schedule_id: report.id
  //   };
  //   try {
  //     const runScheduledReportResponse = await axios.post(url, params);
  //     console.log(runScheduledReportResponse);
  //   } catch (error: any) {
  //     console.error(error);
  //   }
  // };

  const runScheduledReport = async (report: any): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/generate-bad-transactions-report-with-request/${appState.accountId.value}`;
    const today: Date = new Date();
    const startDate: Date = formatIntervalToDate(new Date(today), report);
    const startTimestamp: number = startDate.getTime() / 1000;
    const endTimestamp: number = today.getTime() / 1000;
    const params = {
      account_id: appState.accountId.value,
      start_timestamp: startTimestamp,
      end_timestamp: endTimestamp,
      alerts: report.alerts,
      selected_report_ids: report.selectedIds,
      report_type_string: report.type,
      vehicles: await getGeotabVehicles()
    };
    setLoading(true);
    try {
      const response = await axios({
        url,
        data: params,
        method: 'post',
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const downloadUrl = window.URL.createObjectURL(blob);
      const filename = `transaction-report-${String(startDate.getFullYear()) +
      '-' + String(startDate.getMonth() + 1) +
      '-' + String(startDate.getDate())}_${String(today.getFullYear()) +
      '-' + String(today.getMonth() + 1) +
      '-' + String(today.getDate())}`;
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    } catch (err: any) {
      if (err?.response?.data) {
        const blob = new Blob([err.response.data], { type: 'application/json' });
        const fr = new FileReader();
        fr.addEventListener('load', (e: ProgressEvent<FileReader>) => {
          if (e.target && typeof fr.result === 'string') {
            console.log(JSON.parse(fr.result));
          }
        });
        fr.readAsText(blob);
        openToast({ type: 'error', label: t('Error running report'), autoClose: 2000, theme: 'dark' });
      } else {
        console.log('Error is not in blob form, so returning normal response: ', err);
      }
    }
    setLoading(false);
  };

  const removeScheduledReportFromItems = (id: number): void => {
    const updatedReports: Report[] = [...scheduledReports].filter((report: any): boolean => {
      return report.id !== id;
    });
    setScheduledReports(updatedReports);
  };

  const deleteScheduledReport = async (id: number): Promise<void> => {
    setLoading(true);
    const url: string = `${process.env.REACT_APP_API_URL}/api/report-schedules/${id}`;
    try {
      await axios.delete(url);
      removeScheduledReportFromItems(id);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect((): void => {
    void fetchScheduledReports(paginationModel);
  }, [updatedAccountId]);

  useEffect((): void => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount]);

  const readableTimeConverter = (militaryTime: string): string => {
    const [hours, minutes] = militaryTime.split(':').map(Number);
    const formattedHours: number = hours % 12 || 12;
    const period: string = hours < 12 ? 'AM' : 'PM';
    return `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
  };

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: '',
      width: 50,
      renderCell: (cell: any) => {
        return (
          <div className="flex gap-2">
            <button
              type="button"
              onClick={(): void => {
                setExistingScheduledReport(cell.row);
                setIsEditScheduledReportModalOpen(true);
              }}
              className="transition text-custom-blue-normal hover:text-custom-blue-hover"
            >
              <Icon name="edit"/>
            </button>
            <button
              className="text-custom-blue-normal hover:text-custom-blue-hover"
              onClick={() => {
                void runScheduledReport(cell.row);
              }}
            >
              <Icon name="play"/>
            </button>
          </div>

        );
      }
    },
    {
      field: 'email',
      headerName: t('Email'),
      width: 200
    },
    {
      field: 'interval',
      headerName: t('Interval'),
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>{String(params.row.interval[0]).toUpperCase() + String(params.row.interval.slice(1))}</>
        );
      }
    },
    {
      field: 'day',
      headerName: t('Day'),
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>{String(params.row.day[0]).toUpperCase() + String(params.row.day.slice(1))}</>
        );
      }
    },
    {
      field: 'type',
      headerName: t('Type'),
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>{(params.row.type.includes('-')
            ? String(params.row.type.replace('-', ' ').split(' ').map((word: string) => word.slice(0, 1).toUpperCase() + word.slice(1)).join(' '))
            : String(params.row.type[0]).toUpperCase() + String(params.row.type.slice(1)))}</>
        );
      }
    },
    {
      field: 'time',
      headerName: t('Time'),
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <> {readableTimeConverter(params.row.time.slice(0, 5))} {params.row.timezone}</>
        );
      }
    },
    {
      field: 'delete',
      headerName: '',
      width: 50,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <button
            className="text-custom-blue-normal hover:text-custom-blue-hover"
            onClick={() => {
              void deleteScheduledReport(params.row.id);
            }}
          >
            <Icon name="trash"/>
          </button>
        );
      }
    }
  ];

  return (
    <>
      {loading && (
        <div
          className="p-4 rounded-xl absolute w-full h-full flex justify-center loading items-center bg-opacity-50 bg-black"
        >
          <CircularProgress/>
        </div>
      )}
      <div className="bg-dark-3 p-4 rounded-xl">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-x-2 text-custom-gray-light">
            <Icon name="table-columns"/>
            <span>{t('Reports')}</span>
          </div>
          <div className="flex items-center gap-x-3">
            <IconButton
              icon="play"
              onClick={(): void => {
                setIsRunReportModalOpen(true);
              }}
            />
            {/* <IconButton
              icon="calendar"
              onClick={(): void => {
                setIsScheduledReportModalOpen(true);
              }}
            /> */}
            {isRunReportModalOpen && <ReportModal
                method="run"
                handleCloseModal={(): void => {
                  setIsRunReportModalOpen(false);
                }}
            />}
            {/* isScheduledReportModalOpen && <ReportModal
                method="schedule"
                handleCloseModal={(): void => {
                  setIsScheduledReportModalOpen(false);
                }}
                fetchScheduledReports={() => {
                  void fetchScheduledReports(paginationModel);
                }}
            /> */}
            {isEditScheduledReportModalOpen && <ReportModal
                method="edit"
                handleCloseModal={(): void => {
                  setIsEditScheduledReportModalOpen(false);
                  setExistingScheduledReport(null);
                }}
                existingScheduledReport={existingScheduledReport}
                fetchScheduledReports={(): void => {
                  void fetchScheduledReports(paginationModel);
                }}
            />}
          </div>
        </div>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <DataGrid
            onStateChange={(state: GridInitialState): void => {
              handleDataGridStateChange('reports', state);
            }}
            initialState={appState.dataGridColumnState.value.reports ?? {}}
            disableDensitySelector={true}
            localeText={{
              noRowsLabel: t('No rows'),
              toolbarColumns: t('Columns'),
              toolbarColumnsLabel: t('Columns'),
              toolbarDensity: t('Density'),
              toolbarDensityLabel: t('Density'),
              toolbarDensityCompact: t('Compact'),
              toolbarDensityStandard: t('Standard'),
              toolbarDensityComfortable: t('Comfortable'),
              toolbarQuickFilterPlaceholder: `${t('Search')}...`,
              MuiTablePagination: {
                labelRowsPerPage: t('Rows per Page'),
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
              }
            }}
            pagination
            rowCount={rowCountState}
            rows={scheduledReports}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              setPaginationModel(newModel);
              void fetchScheduledReports(newModel);
            }}
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            density="compact"
            slotProps={{
              toolbar: {
                showQuickFilter: false,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true }
              }
            }}
            slots={{ toolbar: GridToolbar }}
            className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
            sx={{
              height: '50rem',
              '& .MuiDataGrid-row': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                marginTop: '4px',
                borderBottom: 'none'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              '& .MuiDataGrid-cell': {
                border: 'none'
              },
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                borderBottom: 'none',
                marginBottom: '10px'
              },
              border: 0
            }}
          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default withTranslation()(Reports);
