import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Modal from '../../Modal';
import Icon from '../../Icon';
import { Formik } from 'formik';
import Input from '../../Formik/Input';
import Button from '../../Button';
import Checkbox from '../../Formik/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import '../../../../index.css';
import axios, { AxiosError } from 'axios';
import { type Role, userOptions, type User, isAdmin } from '../../../../routes/Authorized/Users/UserTypes';
import { openToast } from '../../../../helpers/toast';
import { iconColorMap, iconTypeMapping } from '../../../../helpers/types';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { getParentAuthHeader } from '../../../../helpers/api';
import { appState } from '../../../../index';

export const canUserView = (activeUserRole: Role | null, userRole: Role, parentUserRole: Role | null = null): boolean => {
  if (activeUserRole === 'super_admin' || parentUserRole === 'super_admin') {
    return true;
  } else if (activeUserRole === 'admin') {
    return !isAdmin(userRole);
  }
  return false;
};

interface Props {
  account: any;
  user: User;
  updateUsers: (updatedUserData: User, method: 'update' | 'remove' | 'create') => void;
  show: boolean;
  onHide: () => void;
}

const UserEditorModal: React.FC<Props> = ({
  account,
  user,
  updateUsers,
  show,
  onHide
}) => {
  const [availableRoles, setAvailableRoles] = useState<Role[]>(userOptions);
  const [userData, setUserData] = useState<User | null>(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userSettings, setUserSettings] = useState<any>(null);
  const [emailSubscriptions, setEmailSubscriptions] = useState({
    no_vehicle_location: false,
    location_mismatch: false,
    fuel_level_mismatch: false,
    fuel_type_mismatch: false,
    filling_time_alert: false,
    filling_percent_alert: false
  });
  const [showInvalidEmail, setShowInvalidEmail] = useState<boolean>(false);
  const UserSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')),
    email: Yup.string().email(t('Invalid Email')).required(t('Required')),
    role: Yup.string().required(t('Required'))
  });
  const fetchData = async (): Promise<void> => {
    try {
      setLoading(true);
      if (!user?.id) {
        return;
      }
      const userSettingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-settings?filter=user_id:${user?.id}(eq)`);
      if (!userSettingsResponse.data.data) {
        return;
      }
      setUserSettings(userSettingsResponse.data.data[0]);
      setEmailSubscriptions(userSettingsResponse.data.data[0].email_subscriptions);
    } catch (error) {
      console.error('Error fetching user settings: ', error);
    } finally {
      setLoading(false);
    }
  };

  const openDeleteConfirmation = (): void => {
    setUserData(user);
    setIsDeleteConfirmationOpen(true);
  };
  const closeDeleteConfirmation = (): void => {
    setUserData(null);
    setIsDeleteConfirmationOpen(false);
  };

  const handleDelete = async (): Promise<void> => {
    try {
      setLoading(true);
      if (!userData) {
        return;
      }
      const headers = getParentAuthHeader();
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/users/${userData.id}`,
        headers ? { headers } : undefined
      );
      updateUsers(userData, 'remove');
      openToast({ type: 'success', label: t('User deleted'), autoClose: 2000, theme: 'dark' });
      closeDeleteConfirmation();
      onHide();
    } catch (error: any) {
      if (error.response.status === 403) {
        openToast({ type: 'error', label: t('You are not authorized to delete users'), autoClose: 2000, theme: 'dark' });
      } else {
        openToast({ type: 'error', label: t('Error deleting user'), autoClose: 2000, theme: 'dark' });
        console.error('Error deleting user: ', error);
      }
    } finally {
      setLoading(false);
    }
  };
  const updateUser = async (updatesSubmitted: any): Promise<boolean> => { // returns true or false to show or hide modal
    try {
      updatesSubmitted.role = updatesSubmitted.role.toLowerCase().replace(' ', '_');
      if (!updatesSubmitted || !userSettings) {
        return false;
      }
      updatesSubmitted.enabled_vehicle_groups = ['*'];
      updatesSubmitted._method = 'PUT';
      updatesSubmitted.userId = userSettings.user_id;
      updatesSubmitted.userSettingsId = userSettings.id;
      const headers = getParentAuthHeader();
      const updateUserSettingsResponse = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/user-settings/${updatesSubmitted.userSettingsId}`, updatesSubmitted,
        headers ? { headers } : undefined
      );
      const updateUserResponse = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/${user?.id}`, updatesSubmitted,
        headers ? { headers } : undefined
      );
      if (!updateUserResponse || !updateUserSettingsResponse) {
        throw new Error('Error updating user');
      }
      updateUsers(updatesSubmitted, 'update');
      openToast({ type: 'success', label: t('User updated'), autoClose: 2000, theme: 'dark' });
      return true;
    } catch (error: AxiosError | any) {
      console.error(error);
      let message = 'Error updating user';
      if (error instanceof AxiosError) {
        message = error?.response?.data.message;
      }
      openToast({
        type: 'error',
        label: t(message),
        autoClose: 2000,
        theme: 'dark'
      });
      return false;
    }
  };

  const createUser = async (userSubmitted: any): Promise<boolean> => { // return true or false to show or hide modal
    try {
      userSubmitted.role = userSubmitted.role.toLowerCase().replace(' ', '_');
      setShowInvalidEmail(false);
      if (!userSubmitted.name || !userSubmitted.email) {
        return false;
      }
      const currentDate = new Date();
      userSubmitted.password = 'TestPass123';
      userSubmitted.email_verified_at = `${currentDate.getFullYear()}-${(`0${currentDate.getMonth() + 1}`).slice(-2)}-${(`0${currentDate.getDate()}`).slice(-2)} ${currentDate.getHours()}:${(`0${currentDate.getMinutes()}`).slice(-2)}:${currentDate.getSeconds()}`;
      userSubmitted.account_id = user?.accountId;
      const url = `${process.env.REACT_APP_API_URL}/api/users`;
      const headers = getParentAuthHeader();
      const createUserResponse = await axios.post(
        url,
        userSubmitted,
        headers ? { headers } : undefined
      );
      if (!createUserResponse) {
        throw new Error('Error creating user');
      }
      userSubmitted.user_id = createUserResponse.data.data.id;
      userSubmitted.enabled_vehicle_groups = ['*'];
      const createUserSettingsResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user-settings`, userSubmitted,
        headers ? { headers } : undefined
      );
      if (!createUserSettingsResponse) {
        throw new Error('Error creating user settings');
      }
      userSubmitted.id = createUserResponse.data.data.id;
      updateUsers(userSubmitted, 'create');
      openToast({ type: 'success', label: t('User created'), autoClose: 2000, theme: 'dark' });
      return true;
    } catch (error: AxiosError | any) {
      console.error(error);
      let message = 'Error creating user';
      if (error instanceof AxiosError) {
        message = error?.response?.data.message;
      }
      openToast({
        type: 'error',
        label: t(message),
        autoClose: 2000,
        theme: 'dark'
      });
      return false;
    }
  };
  useEffect(() => {
    if (show) {
      if (user?.id) {
        void fetchData();
      }
    }
  }, [show]);

  useEffect(() => {
    if (appState.userRole.value === 'super_admin' || (appState.parentData.value ? JSON.parse(appState.parentData.value)?.userRole === 'super_admin' : false)) {
      setAvailableRoles([...availableRoles, 'super_admin']);
    }
  }, []);

  return (
    <Modal
      header={(
        <div className="flex items-center gap-x-2">
          <Icon name={!user?.id ? 'plus' : 'edit'} />
          <p>{!user?.id ? t('Add') : t('Edit')} {t('User')}</p>
        </div>
      )}
      show={show}
      onHide={onHide}
      customZIndex={1001}
    >
      <Formik
        enableReinitialize
        initialValues={{
          ...user,
          name: user?.name ?? '',
          email: user?.email ?? '',
          email_subscriptions: emailSubscriptions
        }}
        validationSchema={UserSchema}
        onSubmit={async (dataSubmitted: User, { setSubmitting }) => {
          let hide = false;
          if (!user?.id) {
            hide = await createUser(dataSubmitted);
          } else {
            hide = await updateUser(dataSubmitted);
          }
          setSubmitting(false);
          if (hide) {
            onHide();
          }
        }}
      >
        {(props: any) => {
          return (
            <form
              onSubmit={props.handleSubmit}
              className="mt-4"
            >
              {(props.isSubmitting || loading) && (
                <div className="loading-overlay">
                  <CircularProgress />
                </div>
              )}

              <div className="flex flex-col gap-y-6">
                <Input
                  name="name"
                  type="default"
                  label={t('Name')}
                />
                <Input
                  name="email"
                  type="email"
                  label={t('Email')}
                />
                {/* Invisible to users, invisible to admins if the user in view is a super admin, completely visible to super admins */}
                {canUserView(appState.userRole.value, user.role, (appState.parentData.value ? JSON.parse(appState.parentData.value)?.userRole : null)) &&
                  <Input
                    name="role"
                    type="select"
                    options={availableRoles.map((option: Role) => {
                      return option.slice(0, 1).toUpperCase() + option.slice(1).replace('_', ' ');
                    })}
                    defaultValue={user.role.slice(0, 1).toUpperCase() + user.role.slice(1).replace('_', ' ')}
                  />}
                {showInvalidEmail && (
                  <p
                    className="text-error-1 text-sm"
                    style={{ marginTop: -15 }}
                  >
                    Invalid email
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-y-2 mt-6">
                <p className="text-xs uppercase font-bold">{t('Receive Emails')}</p>
                <div className="flex flex-col gap-y-2">
                  <Checkbox
                    switchType
                    name="email_subscriptions.no_vehicle_location"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.no_vehicle_location]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.no_vehicle_location} />
                        </div>
                        <p>{t('No Vehicle Location')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="email_subscriptions.location_mismatch"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.location_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.location_mismatch} />
                        </div>
                        <p>{t('Location Mismatch')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="email_subscriptions.fuel_level_mismatch"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.fuel_level_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.fuel_level_mismatch} />
                        </div>
                        <p>{t('Fuel Level Mismatch')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="email_subscriptions.fuel_type_mismatch"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.fuel_type_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.fuel_type_mismatch} />
                        </div>
                        <p>{t('Fuel Type Mismatch')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="email_subscriptions.filling_time_alert"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.filling_time_alert]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.filling_time_alert} />
                        </div>
                        <p>{t('Filling Time Alert')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="email_subscriptions.filling_percent_alert"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.filling_percent_alert]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.filling_percent_alert} />
                        </div>
                        <p>{t('Filling Percent Alert')}</p>
                      </div>
                    )}
                  />
                </div>
              </div>
              {!user?.id
                ? <Button
                  name="submit"
                  disabled={props.isSubmitting}
                  className="!px-8"
                  style={{ marginRight: '10px' }}
                >
                  {t('Submit')}
                </Button>
                : <Button
                  name="submit"
                  disabled={props.isSubmitting}
                  className="!px-8"
                  style={{ marginRight: '10px' }}
                >
                  {t('Submit')}
                </Button>}
              {canUserView(appState.userRole.value, user.role, (appState.parentData.value ? JSON.parse(appState.parentData.value)?.userRole : null)) && user.id && account.primary_user_id !== user.id
                ? <Button
                  type="button"
                  name="delete"
                  onClick={openDeleteConfirmation}
                  disabled={props.isSubmitting}
                  className="!px-8"
                >
                  {t('Delete')}
                </Button>
                : null}
              {isDeleteConfirmationOpen && (
                <Modal
                  header="Confirm Deletion"
                  show={isDeleteConfirmationOpen}
                  onHide={closeDeleteConfirmation}
                  customZIndex={1002}
                >
                  <p>Are you sure you want to delete this user?</p>
                  <div className="flex justify-end">
                    <Button
                      onClick={(): void => {
                        void handleDelete();
                      }}
                      className="!px-8"
                      style={{ marginRight: '10px' }}
                    >
                      {t('Confirm')}
                    </Button>
                    <Button
                      onClick={closeDeleteConfirmation}
                      className="!px-8"
                    >
                      {t('Cancel')}
                    </Button>
                  </div>
                </Modal>
              )}
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default withTranslation()(UserEditorModal);
