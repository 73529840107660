import React, { useEffect, useState } from 'react';
import { Formik, type FormikProps } from 'formik';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Input from '../../Formik/Input';
import Button from '../../Button';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import * as Yup from 'yup';
import IconButton from '../../IconButton';
import DeleteItemModal from '../DeleteItem.modal';
import { fuelCardProviderOptions } from '../../../../helpers/fcp';

interface Props {
  id?: string | number;
  fuelCardProvider?: string;
  name?: string;
  callback: (state: boolean) => void;
  changeFCPAccount?: (fcpID: string | number, fcp: string, uniqueID: string, id: number) => Promise<boolean>;
  createFCPAccount?: (fcp: string, uniqueId: string, id: number) => Promise<boolean>;
  deleteFCPAccount?: (id: number | string) => Promise<void>;
  providers?: any;
  accountFuelCardProviders?: any[];
  currentProvider?: any;
  currentUniqueID?: any;
  accountId: number;
  method?: string;
}

interface FCPEditorValues {
  id: string | number;
  name: string;
  fuelCardProvider: string;
}

const FCPEditorModal: React.FC<Props> = ({
  id = '',
  callback,
  changeFCPAccount,
  createFCPAccount,
  deleteFCPAccount,
  providers,
  accountFuelCardProviders,
  currentProvider,
  currentUniqueID,
  accountId,
  method
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [sameFuelCardProviderCount, setSameFuelCardProviderCount] = useState<number>(0);
  const fcpEditorValues: FCPEditorValues = {
    id: id || '',
    name: currentUniqueID || '',
    fuelCardProvider: currentProvider || ''
  };

  // Used to determine if user should be warned about deleting an AccountToFcp
  useEffect(() => {
    if (accountFuelCardProviders) {
      let count = 0;
      accountFuelCardProviders.forEach((fuelCardProvider: any) => {
        if (fuelCardProvider.fcpCode === currentProvider) {
          count++;
        }
      });
      setSameFuelCardProviderCount(count);
    }
  }, [accountFuelCardProviders]);

  const UserSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')),
    fuelCardProvider: Yup.string().required(t('Required'))
  });

  const onHide = (): void => {
    setIsShown(false);
    callback(true);
  };

  return (
    <>
      <div className="text-left hover:text-custom-blue-normal">
        {method === 'edit' && (
          <button
            type="button"
            onClick={() => {
              setIsShown(true);
            }}
          >
            <Icon
              name="edit"
            />
          </button>
        )}
        {method === 'create' && (
          <IconButton
            className="mt-5"
            icon="plus"
            onClick={() => {
              setIsShown(true);
            }}
          />
        )}
      </div>
      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="plus"/>
            <p>{method === 'create' ? t('Add New') : t('Edit')} {t('FCP')}</p>
          </div>
        )}
        show={isShown}
        onHide={onHide}
        customZIndex={1001}
      >
        <Formik
          validationSchema={UserSchema}
          enableReinitialize
          initialValues={fcpEditorValues}
          onSubmit={(values: FCPEditorValues, { setSubmitting }): void => {
            (async (): Promise<void> => {
              let success: boolean = false;
              if (method === 'edit' && changeFCPAccount) {
                success = await changeFCPAccount(values.id, values.fuelCardProvider, values.name, accountId);
              } else if (method === 'create' && createFCPAccount) {
                success = await createFCPAccount(values.fuelCardProvider, values.name, accountId);
              } else {
                console.error('Invalid FCP Editor method provided');
              }
              if (success) {
                onHide();
              }
              setTimeout(() => {
                setSubmitting(false);
              }, 400);
            })();
          }}
        >
          {(props: FormikProps<any>) => {
            return (
              <form
                onSubmit={props.handleSubmit}
                className="mt-4"
              >
                <div className="flex flex-col gap-y-6">
                  <Input
                    name="fuelCardProvider"
                    type="select"
                    label="Fuel Card Provider"
                    options={fuelCardProviderOptions}
                    placeholder={currentProvider}
                  />
                  <Input
                    name="name"
                    type="default"
                    label="Unique Company Name from FCP"
                  />
                </div>
                <div className="flex justify-between">
                  <Button
                    disabled={props.isSubmitting}
                    className="!px-12"
                  >
                    Submit
                  </Button>
                  {/* Check if this is the last Account to FCP for the current account */}
                  {method === 'edit' &&
                    accountFuelCardProviders &&
                    accountFuelCardProviders.length > 1 &&
                    deleteFCPAccount &&
                    <>
                      {/* Check if this is the last Account to FCP of this type for the current account */}
                      {sameFuelCardProviderCount > 1
                        ? <Button
                        disabled={props.isSubmitting}
                        outline
                        className="!px-12"
                        type="button"
                        onClick={() => {
                          void deleteFCPAccount(id);
                        }}
                        >
                        Delete
                      </Button>
                        : <DeleteItemModal
                        disabled={props.isSubmitting}
                        header="Delete Account to FCP"
                        message={`Warning! This is the last ${currentProvider} Account to FCP record for this account. ` +
                          'Deleting will remove all fuel cards with the same fuel card provider for the account!'}
                        button={true}
                        onDelete={() => {
                          void deleteFCPAccount(id);
                        }}
                        />}
                    </>
                  }
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default withTranslation()(FCPEditorModal);
