import React, { useEffect, useState, useRef } from 'react';
import {
  appState,
  clearCredentials,
  login,
  loginAsChild,
  type LoginData
} from '../../../index';
import axios from 'axios';
import { openToast } from '../../../helpers/toast';

export interface Authentication {
  credentials: {
    database: string,
    userName: string,
    password?: string,
    sessionId: string,
  };
  path: string;
}

const testCredentials: Authentication = {
  credentials: {
    database: 'coloradodot',
    sessionId: 'bFr-BTC9XaGM6Td-mXPBIg',
    userName: 'tucker@bluearrowmail.com'
  },
  path: 'gov.geotab.com'
};

const testParams = {
  credentials: testCredentials.credentials,
  company_guid: '5a81c9b1-8c72-42b4-80b1-e4abf61b815e'
};

/**
 * Prepare authentication credentials and parameters based on the provided event.
 *
 * @param {*} event - The event data.
 * @returns {Array} - An array containing the authentication object and parameters.
 * @throws {Error} - If event data is not available.
 */
const prepareCredentials = (): [Authentication, any] => {
  if (appState.authMessage.value === 'test') return [testCredentials, testParams];

  if (!appState.authMessage.value) throw Error('Event data is not available');

  const params = {
    credentials: appState.authMessage.value.authentication.credentials,
    company_guid: appState.authMessage.value.company_guid
  };

  return [appState.authMessage.value.authentication, params];
};

/**
 * Handles the login process.
 *
 * @async
 * @param {Authentication} authentication - The Geotab authentication object.
 * @param {any} params - The parameters for the login request.
 * @returns {Promise<void>} - A promise that resolves with void.
 * @throws {Error} - If SSO fails, an error is thrown with the corresponding message.
 */

export const handleLogin = async (authentication: Authentication, params: any): Promise<void> => {
  if (!appState.loggedInAsChild.value) {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/validate-geotab-credentials`, params);
    } catch (error: any) {
      if (!error.response?.data?.message) {
        openToast({
          type: 'error',
          label: 'Unable to authenticate, please try again later',
          autoClose: 5000,
          theme: 'dark'
        });
        window.location.replace('/unauthorized');
        return;
      }
      if (error.response.data.message === 'incorrectPassword') {
        console.error('Geotab authentication error: ', error);
        window.location.replace('/user-access');
        return;
      } else {
        console.error('Unknown an occurred during SSO: ', error);
        openToast({
          type: 'error',
          label: 'Server error, please try again later',
          autoClose: 5000,
          theme: 'dark'
        });
        window.location.replace('/unauthorized');
        return;
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const GeotabApi = require('mg-api-js');
  const api = new GeotabApi(authentication);
  await handleUser(api, authentication);
};

/**
 * Handles the user information retrieval and calls the `handleDevice` function.
 *
 * @param {any} api - The Geotab API object.
 * @param {Authentication} authentication - The authentication object.
 * @returns {Promise<void>} - A promise that resolves once the user information is retrieved and the `handleDevice` function is called.
 * @throws {Error} - If failed to retrieve the Geotab user.
 */
const handleUser = async (api: any, authentication: Authentication): Promise<void> => {
  await api.call('Get', {
    typeName: 'User',
    search: { name: authentication.credentials.userName }
  }, async (result: any) => {
    if (!result) throw new Error('Failed to retrieve geotab user.');

    const isMetric = result[0].isMetric;
    const unitOfMeasure = isMetric ? 'metric' : 'imperial';
    const language = result[0].language;
    await handleDevice(api, authentication, unitOfMeasure, language);
  }, (err: any) => {
    console.error('Failed to retrieve geotab user:', err);
  });
};

/**
 * Handles device operations.
 * @param {any} api - The Geotab API.
 * @param {Authentication} authentication - The authentication object.
 * @param {string} unitOfMeasure - The unit of measure.
 * @param {string} language - The language.
 * @returns {Promise<void>} - A promise that resolves when the operation is completed.
 * @throws {Error} - If there is an internal server error or if the device cannot be retrieved.
 */
const handleDevice = async (api: any, authentication: Authentication, unitOfMeasure: string, language: string): Promise<void> => {
  await api.call('Get', {
    typeName: 'Device',
    resultsLimit: 1
  }, async (result: any) => {
    if (result && result.length === 1) {
      if (appState.loggedInAsChild.value) {
        const url = `${process.env.REACT_APP_API_URL}/api/login-as-child`;
        const loginAsChildResponse = await axios.post(url, { account_id: appState.accountId.value });
        loginAsChild(formatLoginResponse(loginAsChildResponse.data, 'en'));
      } else {
        const apiLoginResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/apiLogin`, createApiLoginRequest(authentication, unitOfMeasure));
        if (!apiLoginResponse) throw new Error('Internal Server Error');
        if (apiLoginResponse.data) login(formatLoginResponse(apiLoginResponse.data, language));
      }
    } else {
      throw new Error('Failed to get device with authenticated API.');
    }
  }, (err: any) => {
    console.error('Failed to access devices via Geotab API:', err);
  });
};

/**
 * Handles single sign-on authentication.
 *
 * @param {any} event - The event object containing the authentication credentials.
 * @returns {Promise<void>} - A promise that resolves when the authentication process is completed.
 * @throws {Error} - If the authentication process fails.
 */
const singleSignon = async (): Promise<void> => {
  try {
    const [authentication, params] = prepareCredentials();
    localStorage.setItem('geotabAPI_credentials', JSON.stringify(authentication.credentials));
    localStorage.setItem('geotabAPI_server', authentication.path);
    await handleLogin(authentication, params);
  } catch (err) {
    console.error('Geotab validation attempt failed:', err);
  }
};

/**
 * Creates an API login request object.
 *
 * @param {Authentication} authentication - The authentication object containing user credentials and path.
 * @param {string} unitOfMeasure - The unit of measure for the request.
 * @returns {any} - The API login request object.
 */
const createApiLoginRequest = (authentication: Authentication, unitOfMeasure: string): any => ({
  mygeotab_user_name: authentication.credentials.userName,
  mygeotab_session_id: authentication.credentials.sessionId,
  path: authentication.path,
  database: authentication.credentials.database,
  unit_of_measure: unitOfMeasure,
  is_geotab: '1'
});

/**
 * Formats the login response data.
 *
 * @param {any} response - The response data.
 * @param {string} language - The language of the response.
 * @returns {LoginData} The formatted login data.
 */
export const formatLoginResponse = (response: any, language: string): LoginData => {
  return {
    whitelabel_account_name: response.whitelabel_account_name,
    token: response.token,
    expires_at: response.expires_at,
    user_id: response.user_id,
    user_role: response.user_role,
    account_id: response.account_id,
    path: response.path,
    session_id: response.session_id,
    account_level: response.account_level,
    mygeotab_database_guid: response.mygeotab_database_guid,
    account_name: response.account_name,
    database: response.database,
    language,
    geotab_user_id: response.geotab_user.id,
    geotab_username: response.geotab_user.username,
    logged_in_as_child: response.logged_in_as_child ?? false
  };
};

const handleAsyncMessage = async (): Promise<void> => {
  await singleSignon();
};

const Login: React.FC = () => {
  const [messageReceived, setMessageReceived] = useState(false);
  const messageReceivedRef = useRef(false);
  const [skipSSO, setSkipSSO] = useState(false);
  document.cookie = 'XDEBUG_SESSION=intellij.xdebug; secure=false; samesite=None;';

  useEffect(() => {
    // Bypass sso if authenticated, also checking expiration
    if (appState.isAuthenticated.value && appState.authToken.value && appState.authTokenExpiration.value) {
      if (skipSSO) {
        const expirationDate = new Date(appState.authTokenExpiration.value);
        if (new Date() < expirationDate) {
          window.location.replace('/transactions');
        } else {
          appState.isAuthenticated.value = false;
        }
      }
    }
    const handleMessage = (event: MessageEvent): void => {
      // Check the target of the incoming message
      if (event.data.target === 'login') {
        if (event.data.type === 'auth' && !messageReceivedRef.current) {
          const formData: Record<any, any> = {};
          if (event.data.data[0]) {
            setMessageReceived(true);
            messageReceivedRef.current = true;
            console.log('message received: ', messageReceivedRef.current);
            const formElement = event.data.data[0];
            formData.authentication = {
              credentials: {
                database: formElement.credentials.database,
                password: formElement.credentials.password,
                sessionId: formElement.credentials.sessionId,
                userName: formElement.credentials.userName
              },
              path: formElement.path
            };
            formData.company_guid = event.data.data[1].companyGuid;
            appState.authMessage.value = formData;
            handleAsyncMessage().catch(err => {
              console.error(err);
            });
          }
          const geotabCredentials = localStorage.getItem('geotabAPI_credentials');
          const currentCredentials = event.data.data[0].credentials;
          if (geotabCredentials !== null && geotabCredentials !== '') {
            if (geotabCredentials === JSON.stringify(currentCredentials)) {
              setSkipSSO(true);
            }
            if (event.data[0] === 'currentDatabase') {
              const ssoGeotabDatabase = localStorage.getItem('ssoGeotabDatabase');
              const currentDatabase = event.data[1];
              if (ssoGeotabDatabase !== null && ssoGeotabDatabase !== '') {
                if (ssoGeotabDatabase !== currentDatabase) {
                  clearCredentials();
                  localStorage.setItem('ssoGeotabDatabase', currentDatabase);
                  window.location.href = '/';
                }
              }
            }
          }
        }
      }
    };

    window.parent.postMessage({
      target: 'addin-config',
      type: 'load'
    }, '*');

    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, []);

  useEffect(() => {
    if (appState.authMessage?.value) {
      handleAsyncMessage().catch(err => {
        console.error(err);
      });
      const geotabCredentials = localStorage.getItem('geotabAPI_credentials');
      if (geotabCredentials !== null && geotabCredentials !== '') {
        if (geotabCredentials === JSON.stringify(appState.authMessage.value.authentication.credentials)) {
          setSkipSSO(true);
        }
        if (appState.authMessage.value.authentication) {
          const ssoGeotabDatabase = localStorage.getItem('ssoGeotabDatabase');
          if (ssoGeotabDatabase !== null && ssoGeotabDatabase !== '') {
            if (ssoGeotabDatabase !== JSON.stringify(appState.authMessage.value.authentication.credentials)) {
              clearCredentials();
              localStorage.setItem('ssoGeotabDatabase', JSON.stringify(appState.authMessage.value.authentication.credentials));
              window.location.href = '/';
            }
          }
        }
      }
    }
  }, [appState.authMessage.value]);
  const handleAPIError = (message: string, error: any): void => {
    console.error(message, error);
  };
  return (
    <div>
      Attempting single signon...
    </div>
  );
};

export default Login;
