import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes/App';
import { setCustomerAssets } from './helpers/CustomerAssets';
import './i18n';
import axios from 'axios';
// import { openToast } from './helpers/toast';
import { type GridInitialState } from '@mui/x-data-grid';
import { type Authentication, handleLogin } from './routes/Unauthorized/Login/Login';
import { UNAUTHORIZED_ROUTES } from './enums/ROUTES';
import { effect, signal, type Signal } from '@preact/signals';

import { type Role } from './routes/Authorized/Users/UserTypes';
const UNAUTHORIZED_ROUTES_ARRAY = Object.values(UNAUTHORIZED_ROUTES);

export interface LoginData {
  user_role: Role;
  whitelabel_account_name: string;
  token: string;
  expires_at: number;
  user_id: string;
  account_id: string;
  path: string;
  session_id: string;
  account_level: string;
  account_name: string;
  mygeotab_database_guid: string;
  database: string;
  language: string;
  logged_in_as_child: boolean;
  geotab_user_id: string;
  geotab_username: string;
}

interface ParentData {
  userRole: Role;
  userId: string;
  accountId: string;
  path: string;
  accountLevel: string;
  accountName: string;
  token: string;
  database: string;
  geotabUserName: string;
  geotabUserId: string;
}

interface DataGridColumnState {
  fuelCards: GridInitialState | null;
  transactions: GridInitialState | null;
  mostExceptions: GridInitialState | null;
  fuelingEfficiency: GridInitialState | null;
  dismissedTransactions: GridInitialState | null;
  reports: GridInitialState | null;
  superAdmin: GridInitialState | null;
  systemAdmin: GridInitialState | null;
}

type DataGridPage =
  'fuelCards' |
  'transactions' |
  'mostExceptions' |
  'fuelingEfficiency' |
  'dismissedTransactions' |
  'reports' |
  'superAdmin' |
  'systemAdmin';

const defaultDataGridColumnState: DataGridColumnState = {
  fuelCards: null,
  transactions: null,
  mostExceptions: null,
  fuelingEfficiency: null,
  dismissedTransactions: null,
  reports: null,
  superAdmin: null,
  systemAdmin: null
};

export const appState: Record<string, Signal> = {
  activeGeotabUserId: signal<string | null>(localStorage.getItem('activeGeotabUserId')),
  activeGeotabUserName: signal<string | null>(localStorage.getItem('activeGeotabUserName')),
  authMessage: signal<any | null>(localStorage.getItem('authMessage')),
  authToken: signal<string | null>(localStorage.getItem('authToken')),
  authTokenExpiration: signal<string | null>(localStorage.getItem('authTokenExpiration')),
  isAuthenticated: signal<boolean>(!!localStorage.getItem('authToken')),
  childAuthToken: signal<string | null>(localStorage.getItem('childAuthToken')),
  childAuthTokenExpiration: signal<string | null>(localStorage.getItem('childAuthTokenExpiration')),
  isChildAuthenticated: signal<boolean>(!!localStorage.getItem('childAuthToken')),
  loggedInAsChild: signal<boolean>(localStorage.getItem('loggedInAsChild') === 'true'),
  parentData: signal<string | null>(localStorage.getItem('parentData')),
  userId: signal<string | null>(localStorage.getItem('userId')),
  userRole: signal<Role | null>(localStorage.getItem('userRole') as Role ?? null),
  accountId: signal<string | null>(localStorage.getItem('accountId')),
  path: signal<string | null>(localStorage.getItem('path')),
  sessionId: signal<string | null>(localStorage.getItem('sessionId')),
  database: signal<string | null>(localStorage.getItem('database')),
  ssoGeotabDatabase: signal<string | null>(localStorage.getItem('ssoGeotabDatabase')),
  currentDatabase: signal<string | null>(localStorage.getItem('currentDatabase')),
  accountLevel: signal<string | null>(localStorage.getItem('accountLevel')),
  accountName: signal<string | null>(localStorage.getItem('accountName')),
  companyGuid: signal<string | null>(localStorage.getItem('companyGuid')),
  currentRoute: signal<string | null>(null),
  language: signal<string | null>(localStorage.getItem('language')),
  groupFilterCookie: signal<string | null>(getCookie('ba_group_filter')),
  dataGridColumnState: signal<DataGridColumnState>((() => {
    const state: string | null = localStorage.getItem('dataGridColumnState');
    if (state) {
      return JSON.parse(state);
    }
    return defaultDataGridColumnState;
  })())
};

effect(() => {
  if ((appState.userRole.value && appState.userRole.value !== 'super_admin') &&
     (window.location.pathname === '/superadmin' || window.location.pathname === '/system-admin')) {
    window.location.pathname = '/transactions';
  }
});

addEventListener('storage', (event: StorageEvent) => {
  if (event.key === 'loggedInAsChild') {
    appState.loggedInAsChild.value = event.newValue === 'true';
    return;
  }
  if (event.key === 'dataGridColumnState' && event.newValue) {
    appState.dataGridColumnState.value = JSON.parse(event.newValue);
    return;
  }
  if (event.key === 'authMessage' && event.newValue) {
    appState.authMessage.value = JSON.parse(event.newValue);
    return;
  }
  if (event.key === 'isAuthenticated') {
    appState.isAuthenticated.value = !!event.newValue;
    return;
  }

  for (const key in appState) {
    if (key === event.key && event.newValue) {
      appState[key].value = event.newValue;
    }
  }
});

export const handleDataGridStateChange = (page: DataGridPage, state: GridInitialState): void => {
  appState.dataGridColumnState.value[page] = state;
  localStorage.setItem('dataGridColumnState', JSON.stringify(appState.dataGridColumnState.value));
};

export function getCookie (cookieName: string): string | null {
  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }
  return null; // Return null if the cookie is not found
}

axios.interceptors.response.use(
  (response: any) => response,
  error => {
    if (error.response && error.response.status === 401) {
      window.location.replace('/unauthorized');
    } else {
      throw error;
    }
  }
);
const checkExpiration = (): void => {
  appState.isAuthenticated.value = verifyTokenExpiration(appState.authTokenExpiration.value);
  appState.isChildAuthenticated.value = verifyTokenExpiration(appState.childAuthTokenExpiration.value);
  if (!appState.isAuthenticated.value) {
    const pathname = window.location.pathname as UNAUTHORIZED_ROUTES;

    if (!UNAUTHORIZED_ROUTES_ARRAY.includes(pathname)) {
      window.location.replace('/unauthorized');
    }
  }
  if (!appState.isChildAuthenticated.value) {
    logoutChild();
  }
};
setInterval(checkExpiration, 30000);

const verifyTokenExpiration = (timestamp: string | null): boolean => {
  if (timestamp) {
    const expiration = new Date(timestamp);
    const now = new Date();
    // ! toast disabled so the warning does not show
    // Checks if token expires within 5 minutes
    // if (expiration.getTime() - now.getTime() <= (60 * 1000) * 5 && expiration.getTime() - now.getTime() > 0) {
    //   const difference = expiration.getTime() - now.getTime();
    //   let countdown;
    //   let unitOfTime;

    //   if (difference / 60000 < 1) {
    //     countdown = difference / 1000;
    //     unitOfTime = 'seconds';
    //   } else {
    //     countdown = difference / 60000;
    //     unitOfTime = 'minutes';
    //   }
    //   openToast({
    //     type: 'error',
    //     label: `Warning: Session expires in ${countdown.toFixed(0)} ${unitOfTime}`,
    //     autoClose: 2000,
    //     theme: 'dark'
    //   });
    // }
    return now < expiration;
  } else {
    return false;
  }
};

export const login = (data: LoginData): void => {
  // Update local storage
  localStorage.setItem('authToken', `Bearer ${data.token}`);
  localStorage.setItem('authTokenExpiration', `${data.expires_at}`);
  localStorage.setItem('userId', data.user_id);
  localStorage.setItem('userRole', data.user_role);
  localStorage.setItem('accountId', data.account_id);
  localStorage.setItem('accountName', data.account_name);
  localStorage.setItem('path', data.path);
  localStorage.setItem('sessionId', data.session_id);
  localStorage.setItem('database', data.database);
  localStorage.setItem('loggedInAsChild', 'false');
  localStorage.setItem('accountLevel', data.account_level);
  localStorage.setItem('language', data.language);
  localStorage.setItem('activeGeotabUserName', data.geotab_username);
  localStorage.setItem('activeGeotabUserId', data.geotab_user_id);
  localStorage.setItem('companyGuid', data.mygeotab_database_guid);

  // Update signals
  appState.authToken.value = `Bearer ${data.token}`;
  appState.authTokenExpiration.value = `${data.expires_at}`;
  appState.userId.value = data.user_id;
  appState.userRole.value = data.user_role;
  appState.accountId.value = data.account_id;
  appState.accountName.value = data.account_name;
  appState.path.value = data.path;
  appState.sessionId.value = data.session_id;
  appState.database.value = data.database;
  appState.isAuthenticated.value = true;
  appState.accountLevel.value = data.account_level;
  appState.loggedInAsChild.value = data.logged_in_as_child;
  appState.language.value = data.language;
  appState.companyGuid.value = data.mygeotab_database_guid;
  setCustomerAssets(data.whitelabel_account_name);
  window.location.href = '/transactions'; // once logged in... go to transactions page (isAuthenticated effect doesn't always work)
};

export const loginAsChild = (data: LoginData): void => {
  // console.log('Logging in as child');
  /* Update parent data
     * If logged in as a superadmin, the parent data object will always be that account, this is for when logging in as a
     * reseller child account we will still have the super admin account to fall back onto
     */
  let parentDataObject: ParentData | null = appState.parentData.value ? JSON.parse(appState.parentData.value) : null;
  if (parentDataObject && parentDataObject?.accountLevel !== 'superadmin') {
    // console.log('Not a superadmin, so updating parent data');
    parentDataObject.accountId = appState.accountId.value ?? '';
    parentDataObject.userId = appState.userId.value ?? '';
    parentDataObject.userRole = appState.userRole.value ?? 'user';
    parentDataObject.path = appState.path.value ?? '';
    parentDataObject.accountLevel = appState.accountLevel.value ?? '';
    parentDataObject.accountName = appState.accountName.value ?? '';
    parentDataObject.token = appState.authToken.value ?? '';
    parentDataObject.database = appState.database.value ?? '';
    parentDataObject.geotabUserName = appState.activeGeotabUserName.value ?? '';
    parentDataObject.geotabUserId = appState.activeGeotabUserId.value ?? '';
  } else if (!parentDataObject) {
    // console.log('Parent data not set, so updating parent data');
    parentDataObject = {
      accountId: appState.accountId.value ?? '',
      userId: appState.userId.value ?? '',
      userRole: appState.userRole.value ?? 'user',
      path: appState.path.value ?? '',
      accountLevel: appState.accountLevel.value ?? '',
      accountName: appState.accountName.value ?? '',
      token: appState.authToken.value ?? '',
      database: appState.database.value ?? '',
      geotabUserName: appState.activeGeotabUserName.value ?? '',
      geotabUserId: appState.activeGeotabUserId.value ?? ''
    };
  }
  // console.log('Parent data object: ', parentDataObject);
  localStorage.setItem('parentData', JSON.stringify(parentDataObject));
  appState.parentData.value = JSON.stringify(parentDataObject);

  // Set child auth token
  localStorage.setItem('childAuthToken', `Bearer ${data.token}`);
  localStorage.setItem('childAuthTokenExpiration', `${data.expires_at}`);
  appState.childAuthToken.value = `Bearer ${data.token}`;
  appState.childAuthTokenExpiration.value = `${data.expires_at}`;

  // Set data for banner
  localStorage.setItem('accountName', data.account_name);
  localStorage.setItem('accountLevel', data.account_level);
  localStorage.setItem('loggedInAsChild', 'true');
  appState.accountName.value = data.account_name;
  appState.accountLevel.value = data.account_level;
  appState.loggedInAsChild.value = true;

  // Set child account data
  localStorage.setItem('accountId', data.account_id);
  localStorage.setItem('userId', data.user_id);
  localStorage.setItem('userRole', data.user_role);
  localStorage.setItem('path', data.path);
  localStorage.setItem('database', data.database);
  localStorage.setItem('activeGeotabUserName', data.geotab_username);
  localStorage.setItem('activeGeotabUserId', data.geotab_user_id);
  appState.accountId.value = data.account_id;
  appState.userId.value = data.user_id;
  appState.userRole.value = data.user_role;
  appState.path.value = data.path;
  appState.database.value = data.database;
  appState.activeGeotabUserName.value = data.geotab_username;
  appState.activeGeotabUserId.value = data.geotab_user_id;

  window.location.replace('../transactions');
};

export const logoutChild = (): void => {
  // console.log('Logging out');
  const parentDataString: string | null = localStorage.getItem('parentData');
  if (!parentDataString) {
    // console.log('No parent data, nothing to logout from');
    return;
  }
  const parentDataObject = JSON.parse(parentDataString);
  if (window.location.pathname === '/resellers' && parentDataObject?.accountLevel === 'superadmin') {
    // console.log('You are a super admin logged in as a reseller, so we don\'t ' +
    // 'want to log out of the reseller when visiting the reseller dashboard');
    return;
  }
  // Set parent as active account
  switch (parentDataObject?.accountLevel) {
    case 'superadmin':
      console.log('You are a superadmin, setting your state back to superadmin');
      setParentAsActive(parentDataObject);
      window.location.reload();
      break;
    case 'reseller':
      console.log('You are a reseller, setting your state back to reseller');
      setParentAsActive(parentDataObject);
      window.location.reload();
      break;
  }
  // Logout of child
  localStorage.removeItem('childAuthToken');
  localStorage.removeItem('childAuthTokenExpiration');
  localStorage.setItem('loggedInAsChild', 'false');
  localStorage.removeItem('parentData');
  appState.childAuthToken.value = null;
  appState.childAuthTokenExpiration.value = null;
  appState.loggedInAsChild.value = false;
  appState.parentData.value = null;
};

const determineDestination = (parentDataObject: ParentData): string => {
  switch (parentDataObject?.accountLevel) {
    case 'superadmin':
      return '/superadmin';
    case 'reseller':
      return '/resellers';
    default:
      return '/transactions';
  }
};

const setParentAsActive = (parentDataObject: ParentData): void => {
  localStorage.setItem('userId', parentDataObject.userId);
  localStorage.setItem('userRole', parentDataObject.userRole);
  localStorage.setItem('accountId', parentDataObject.accountId);
  localStorage.setItem('accountLevel', parentDataObject.accountLevel);
  localStorage.setItem('accountName', parentDataObject.accountName);
  localStorage.setItem('path', parentDataObject.path);
  localStorage.setItem('database', parentDataObject.database);
  localStorage.setItem('activeGeotabUserName', parentDataObject.geotabUserName);
  localStorage.setItem('activeGeotabUserId', parentDataObject.geotabUserId);
  appState.userId.value = parentDataObject.userId;
  appState.userRole.value = parentDataObject.userRole;
  appState.accountId.value = parentDataObject.accountId;
  appState.accountLevel.value = parentDataObject.accountLevel;
  appState.accountName.value = parentDataObject.accountName;
  appState.path.value = parentDataObject.path;
  appState.database.value = parentDataObject.database;
  appState.activeGeotabUserName.value = parentDataObject.geotabUserName;
  appState.activeGeotabUserId.value = parentDataObject.geotabUserId;
};

export const clearCredentials = (): void => {
  // Clear necessary signals
  appState.activeGeotabUserId.value = null;
  appState.activeGeotabUserName.value = null;
  appState.authToken.value = null;
  appState.authTokenExpiration.value = null;
  appState.isAuthenticated.value = false;
  appState.childAuthToken.value = null;
  appState.childAuthTokenExpiration.value = null;
  appState.isChildAuthenticated.value = false;
  appState.loggedInAsChild.value = false;
  appState.parentData.value = null;
  appState.userId.value = null;
  appState.userRole.value = 'user';
  appState.accountId.value = null;
  appState.path.value = null;
  appState.sessionId.value = null;
  appState.database.value = null;
  appState.ssoGeotabDatabase.value = null;
  appState.currentDatabase.value = null;
  appState.accountLevel.value = null;
  appState.accountName.value = null;
  appState.companyGuid.value = null;
  appState.currentRoute.value = null;
  appState.language.value = null;
  appState.groupFilterCookie.value = null;
  appState.dataGridColumnState.value = defaultDataGridColumnState;

  // Clear necessary local storage
  localStorage.removeItem('activeGeotabUserId');
  localStorage.removeItem('activeGeotabUserName');
  localStorage.removeItem('authToken');
  localStorage.removeItem('authTokenExpiration');
  localStorage.removeItem('isAuthenticated');
  localStorage.removeItem('childAuthToken');
  localStorage.removeItem('childAuthTokenExpiration');
  localStorage.removeItem('isChildAuthenticated');
  localStorage.removeItem('loggedInAsChild');
  localStorage.removeItem('parentData');
  localStorage.removeItem('userId');
  localStorage.removeItem('userRole');
  localStorage.removeItem('accountId');
  localStorage.removeItem('path');
  localStorage.removeItem('sessionId');
  localStorage.removeItem('database');
  localStorage.removeItem('ssoGeotabDatabase');
  localStorage.removeItem('currentDatabase');
  localStorage.removeItem('accountLevel');
  localStorage.removeItem('accountName');
  localStorage.removeItem('companyGuid');
  localStorage.removeItem('currentRoute');
  localStorage.removeItem('language');
  localStorage.removeItem('groupFilterCookie');
  localStorage.removeItem('dataGridColumnState');
};

export const refreshLogin = (): void => {
  const geotabCredentials: string | null = localStorage.getItem('geotabAPI_credentials');
  const path: string | null = localStorage.getItem('geotabAPI_server');
  if (geotabCredentials && path) {
    const credentials = JSON.parse(geotabCredentials);
    const authentication: Authentication = {
      credentials,
      path
    };
    const params = {
      credentials: authentication.credentials,
      company_guid: appState.companyGuid.value
    };
    clearCredentials();
    void handleLogin(authentication, params);
  } else {
    window.location.href = '/';
    console.error('Missing Geotab credentials or path: ', geotabCredentials, ' ,', path);
  }
};

export const useSignal = <Type,>(signal: Signal<Type>): Type => {
  const [value, setValue] = useState<Type>(signal.value);
  useEffect(() => {
    const cleanup = effect(() => {
      setValue(signal.value);
    });
    return cleanup;
  }, [signal]);
  return value;
};

ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
).render(
  <App />
);
