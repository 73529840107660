export const userOptions: Role[] = ['user', 'admin'];

export type Role = 'user' | 'admin' | 'super_admin';

export interface User {
  accountId: number | null;
  email: string | null;
  geotabUsername: string | null;
  geotabUserId: number | null;
  id: number | null;
  name: string | null;
  role: Role;
}

export interface GeotabUser {
  accountId: number | null;
  database: string | null;
  geotabUsername: string | null;
  hasGeotabUser: boolean;
  id: number | null;
  userId: number | null;
}

export const defaultUser: User = {
  accountId: null,
  email: null,
  geotabUsername: null,
  geotabUserId: null,
  id: null,
  name: null,
  role: 'user'
};

export const isAdmin = (role: Role | null): boolean => {
  return role === 'admin' || role === 'super_admin';
};
