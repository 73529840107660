import React, { useState } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import FCPEditorModal from './FCPEditor.modal';
import AccountToFcpSettingsModal from './AccountToFcpSettings.modal';
import axios, { AxiosError } from 'axios';
import { DataGrid, type GridColDef, type GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { openToast } from '../../../../helpers/toast';
import { fuelCardProviderOptions } from '../../../../helpers/fcp';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  params: GridRenderCellParams;
}

const FCPModal: React.FC<Props> = ({ params }) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [fuelCardProviders, setFuelCardProviders] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchFCPS = async (): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/account-to-fcps?filter=account_id:${params.row.id}(eq)`;
    try {
      setLoading(true);
      const response = await axios.get(url);
      if (response.data.data) {
        setFuelCardProviders(transformData(response.data.data));
      }
    } catch (err) {
      console.log(err);
      openToast({
        type: 'error',
        label: 'Error getting fcp\'s',
        autoClose: 2000,
        theme: 'dark'
      });
    } finally {
      setLoading(false);
    }
  };

  const changeFCPAccount = async (fcpID: string | number, fcp: string, uniqueID: string, id: number): Promise<boolean> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/account-to-fcps/${fcpID}`;
    const params: { fcp_code: string, account_id: number, fcp_company_unique_id: string, } = {
      account_id: id,
      fcp_code: fcp.toUpperCase(),
      fcp_company_unique_id: uniqueID
    };
    try {
      await axios.put(url, params);
      const updatedArray: any[] = fuelCardProviders.map((item: any) =>
        item.id === fcpID ? { ...item, uniqueIdentifier: uniqueID, fcpCode: fcp } : item
      );
      setFuelCardProviders(updatedArray);
      openToast({
        type: 'success',
        label: 'FCP updated',
        autoClose: 2000,
        theme: 'dark'
      });
      return true;
    } catch (error: AxiosError<any> | any) {
      if (error instanceof AxiosError) {
        openToast({
          type: 'error',
          label: error?.response?.data?.message ?? 'Error updating FCP',
          autoClose: 2000,
          theme: 'dark'
        });
      } else {
        openToast({
          type: 'error',
          label: 'Error updating FCP',
          autoClose: 2000,
          theme: 'dark'
        });
      }
      console.log(error);
      return false;
    }
  };

  const createFCPAccount = async (fcp: string, uniqueID: string, id: number): Promise<boolean> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/account-to-fcps`;
    const params: { fcp_code: string, account_id: number, fcp_company_unique_id: string, } = {
      account_id: id,
      fcp_code: fcp.toUpperCase(),
      fcp_company_unique_id: uniqueID
    };
    try {
      const response = await axios.post(url, params);
      setFuelCardProviders([...fuelCardProviders, {
        id: response.data.data.accountToFcp.id,
        uniqueIdentifier: uniqueID,
        fcpCode: fcp
      }]);
      openToast({
        type: 'success',
        label: 'Entry added',
        autoClose: 2000,
        theme: 'dark'
      });
      return true;
    } catch (err) {
      console.log(err);
      openToast({
        type: 'error',
        label: 'Error adding entry',
        autoClose: 2000,
        theme: 'dark'
      });
      return false;
    }
  };

  const deleteFCPAccount = async (id: number | string): Promise<void> => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/account-to-fcps/${id}`;
      await axios.delete(url);
      setFuelCardProviders([...fuelCardProviders].filter((fcp: any) => fcp.id !== id));
      openToast({
        type: 'success',
        label: 'FCP Account has been deleted',
        autoClose: 2000,
        theme: 'dark'
      });
    } catch (error: any) {
      openToast({
        type: 'error',
        label: 'Error deleting FCP Account',
        autoClose: 2000,
        theme: 'dark'
      });
      console.error(error);
    }
  };

  const onHide = (): void => {
    setIsShown(false);
  };

  const transformData = (fcpList: any): any => {
    if (!fcpList) {
      return [];
    }
    return fcpList.map((fcp: any) => ({
      id: fcp.id,
      uniqueIdentifier: fcp.fcp_company_unique_id,
      fcpCode: fcp.fcp_code,
      accountId: fcp.account_id
    }));
  };

  const columns: GridColDef[] = [
    {
      field: 'editButton',
      headerName: '',
      width: 30,
      renderCell: (params: any) => {
        return (
          <FCPEditorModal
            method={'edit'}
            key={'editEntry' + `${params.row.id}`}
            callback={(state: boolean) => {
              setIsShown(state);
            }}
            id={params.row.id}
            name={params.row.fcp_company_unique_id}
            fuelCardProvider={params.row.fcp_code}
            changeFCPAccount={changeFCPAccount}
            deleteFCPAccount={deleteFCPAccount}
            providers={fuelCardProviderOptions}
            accountFuelCardProviders={fuelCardProviders}
            currentProvider={params.row.fcpCode}
            currentUniqueID={params.row.uniqueIdentifier}
            accountId={params.row.accountId}
          />
        );
      }
    },
    { field: 'uniqueIdentifier', headerName: 'Unique company name', width: 200 },
    { field: 'fcpCode', headerName: 'Fuel Card Provider', width: 150 }
  ];

  return (
    <>
      <button
        type="button"
        className="text-custom-blue-normal hover:text-custom-blue-hover"
        onClick={(): void => {
          fetchFCPS().then();
          setIsShown(true);
        }}
      >
        <Icon
          name="gear"
          size="xl"
        />
      </button>
      {isShown && <Modal
        key={'fcpModal' + `${params.row.id}`}
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="gear" />
            <p>FCP</p>
          </div>
        )}
        show={isShown}
        onHide={onHide}
        maxWidth={'35rem'}
        maxHeight={'50rem'}
      >
        {loading && (
          <div
            className="w-full h-full absolute inset-0 flex justify-center items-center z-[1000] bg-opacity-20 bg-black"
          >
            <CircularProgress />
          </div>
        )}
        <div className="flex flex-col gap-y-2 w-full mt-3">
          <label
            className="text-xs w-full"
          >Unique Company Name</label>
          <input
            name="search"
            type="text"
            id="FCPSearch"
            className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs"
            placeholder="Search..."
            value={searchValue}
            onChange={(event): void => {
              setSearchValue(event.target.value);
            }}
          />
        </div>
        <DataGrid
          disableDensitySelector
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
          rows={fuelCardProviders.filter((fcp: any) => (
            fcp.uniqueIdentifier.toUpperCase().includes(searchValue.toUpperCase())
          )).sort((a: any, b: any): number => {
            const keyA = a.uniqueIdentifier;
            const keyB = b.uniqueIdentifier;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          })}
          columns={columns}
          density="compact"
          slots={{ toolbar: GridToolbar }}
          className="flex text-sm bg-dark-3 text-white py-1 rounded-lg gap-x-6 cursor-pointer"
          sx={{
            height: '30rem',
            '& .MuiDataGrid-row': {
              borderRadius: '80px',
              backgroundColor: '#242838',
              marginTop: '4px',
              borderBottom: 'none'
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none'
            },
            '& .MuiDataGrid-cell': {
              border: 'none'
            },
            '& .MuiDataGrid-columnHeaders': {
              borderRadius: '80px',
              backgroundColor: '#242838',
              borderBottom: 'none',
              marginTop: '10px',
              marginBottom: '10px'
            },
            border: 0
          }}
        />
        <div className="flex gap-x-2">
          <FCPEditorModal
            method={'create'}
            key={'addEntry'}
            callback={(state: boolean): void => {
              setIsShown(state);
            }}
            providers={fuelCardProviderOptions}
            createFCPAccount={createFCPAccount}
            accountId={params.row.id}
          />
          <AccountToFcpSettingsModal
            accountId={params.row.id}
          />
        </div>
      </Modal>}
    </>
  );
};

export default FCPModal;
