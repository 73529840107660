import React, { type ReactElement, useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Button from '../../Button';
import TransactionDetailsShareModal from './TransactionDetailsShare.modal';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { transactionTypeToHeader } from '../../../../helpers/types';
import axios, { type AxiosResponse } from 'axios';
import { Tooltip } from '@mui/material';
import TransactionLocation from './TransactionLocation';
import TransactionLocationUpdate from './TransactionLocationUpdate.modal';
import DismissTransaction from './DismissTransaction.modal';
import ReassignTransaction from './ReassignTransaction.modal';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { openToast } from '../../../../helpers/toast';
import { appState } from '../../../../index';
import DateTimeToolTip from './DateTimeToolTip';
import { t } from 'i18next';
import moment from 'moment-timezone';
import { type TransformedTransaction, type GasStation } from '../../../../types/transactions';
import { isValidCell } from '../../../../helpers/columns';

interface GasStationResponse {
  data: GasStation[];
}

interface Props {
  transaction: TransformedTransaction;
  isLoading?: boolean;
  removedDismissedTransaction: (id: number, transactions?: any[]) => void;
  group?: boolean;
}

const isLabelMatched = (label: any, type: any, subtypes: any): boolean => {
  if (label === transactionTypeToHeader[type]) {
    return true;
  }
  if (subtypes) {
    return subtypes.some((subtype: any) => label === transactionTypeToHeader[subtype]);
  }
  return false;
};

const TransactionDetailsModal: React.FC<Props> = ({
  transaction,
  removedDismissedTransaction,
  group = false
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [transactionId] = useState(transaction.id);
  const [transactionData, setTransactionData] = useState<TransformedTransaction>(transaction);
  const [isDataFetched, setDataFetched] = useState(false);
  const [accountData, setAccountData] = useState<any>({});
  const [accountSettings, setAccountSettings] = useState<any>({});
  const [userSettings, setUserSettings] = useState<any>({});
  const [clickable, setClickable] = useState(false);
  const [gasStation, setGasStation] = useState<GasStation | null>(null);
  const [gasStationX, setGasStationX] = useState<any>();
  const [gasStationY, setGasStationY] = useState<any>();
  const [vehicleLocation, setVehicleLocation] =
      useState<{ latitude: number, longitude: number, } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showIfta, setShowIfta] = useState<boolean>(false);
  const iconName: IconName = transactionData?.icon;

  // For Debugging State
  // useEffect(() => {
  //   if (isShown) {
  //     console.log('Transaction Details Modal Update: ', transactionData);
  //   }
  // }, [transactionData]);

  useEffect(() => {
    if (!isShown) return;
    const fetchVehicles = async (): Promise<void> => {
      setLoading(true);
      try {
        document.cookie = 'XDEBUG_SESSION=intellij.xdebug;';
        setVehicleLocation({ latitude: transaction.vehicleLatitude, longitude: transaction.vehicleLongitude });
        const accResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/accounts/${appState.accountId.value}`
        );
        setAccountData(accResponse.data.data);
        const accSettingsRes = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/account-settings?filter=account_id:${appState.accountId.value}(eq)`
        );
        setAccountSettings(accSettingsRes.data.data[0]);
        setShowIfta(accSettingsRes.data.data[0].fix_ifta);
        const userSettingsRes = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-settings?filter=user_id:${appState.userId}(eq)`);
        setUserSettings(userSettingsRes.data.data[0]);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching account settings:', error);
      }
    };

    const fetchTransaction = async (): Promise<boolean> => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/api/gas-stations?include=correctedGasStation&filter=id:${transactionData.gasStationId}(eq)`;
        const gasStationResponse: AxiosResponse<GasStationResponse> = await axios.get(url);
        if (gasStationResponse.data.data.length) {
          setGasStation(gasStationResponse.data.data[0]);
          if (gasStationResponse.data.data[0].correctedGasStation.length) {
            setGasStationX(Number(gasStationResponse.data.data[0].correctedGasStation[0].longitude));
            setGasStationY(Number(gasStationResponse.data.data[0].correctedGasStation[0].latitude));
          } else {
            setGasStationX(Number(gasStationResponse.data.data[0].longitude));
            setGasStationY(Number(gasStationResponse.data.data[0].latitude));
          }
        }
        return true;
      } catch (error) {
        console.error('Error fetching transaction data:', error);
        throw error;
      }
    };
    fetchTransaction()
      .then((result: boolean) => {
        if (result) {
          fetchVehicles().then(() => {
            setDataFetched(true);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [isShown]);

  const updateGasStationLocation = (setSaving: Dispatch<SetStateAction<boolean>>, setClickable: any): void => {
    const timestamp = transactionData.timestamp;
    if (!timestamp) {
      return;
    }
    (async () => {
      setCorrectedGasStation(await getGoogleTimezone(timestamp));
      setSaving(false);
      setClickable(false);
    })();
  };

  const getGoogleTimezone = async (timestamp: number): Promise<string> => {
    const params = {
      location: String(gasStationY) + ',' + String(gasStationX),
      timestamp,
      url: 'timezone/json'
    };
    const url = `${process.env.REACT_APP_API_URL}/api/make-google-maps-request`;
    try {
      const response = await axios.post(url, { params });
      return response.data.timeZoneId;
    } catch (err) {
      openToast({ type: 'error', label: 'Error making map request', autoClose: 2000, theme: 'dark' });
      console.log(err);
      return 'err';
    }
  };

  const setCorrectedGasStation = (timezone: string): void => {
    if (!gasStation) {
      return;
    }
    (async () => {
      const params = {
        gas_station_id: gasStation.id,
        account_id: appState.accountId.value,
        latitude: String(gasStationY),
        longitude: String(gasStationX),
        timezone
      };
      const url = `${process.env.REACT_APP_API_URL}/api/corrected-gas-stations`;
      try {
        const response = await axios.post(url, params);
        openToast({
          type: 'success',
          label: t('Correct gas station retrieved, sending this transaction to the queue service to be reprocessed.'),
          autoClose: 5000,
          theme: 'dark'
        });
        reprocessTransactions(params, response.data.data.id);
      } catch (err) {
        openToast({ type: 'error', label: 'Error retrieving the correct gas station.', autoClose: 2000, theme: 'dark' });
        console.log(err);
      }
    })();
  };

  const reprocessTransactions = (params: any, correctedGasStationId: string): void => {
    const timestamp = transactionData.timestamp;
    if (!timestamp) {
      return;
    }
    (async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/recheck-fuel-transactions`;
      params = {
        corrected_gas_station_id: correctedGasStationId,
        latitude: String(params.latitude),
        longitude: String(params.longitude),
        timestamp
      };
      try {
        const response = await axios.post(url, params);
        if (!response) {
          throw new Error('Internal Server Error.');
        }
        openToast({
          type: 'success',
          label: t('Reprocessing has begun, this can take a significant amount of time,' +
            'so this occurs in the background. Any transactions related to this corrected' +
            'gas station may look weird until this reprocessing has finished.'),
          autoClose: 5000,
          theme: 'dark'
        });
      } catch (err) {
        openToast({ type: 'error', label: t('Error reprocessing transaction'), autoClose: 2000, theme: 'dark' });
        console.log(err);
      }
    })();
  };

  const renderVehicleGroups = (): ReactElement | string | null => {
    const vehicleGroups: string[] | null = transactionData.vehicleGroups;
    if (!vehicleGroups) {
      return null;
    }
    if (vehicleGroups.length > 1) {
      return (
        <Tooltip
          title={vehicleGroups.join(', ')}
          placement="right"
          arrow
        >
          <span className="tooltip-container">Multiple</span>
        </Tooltip>
      );
    }
    return vehicleGroups[0];
  };

  const openInNewTab = (url: string): void => {
    window.open(url, '_blank', 'noreferrer');
  };

  const openTrip = async (): Promise<void> => {
    try {
      const datetime = transactionData.datetime;
      if (!datetime) {
        return;
      }
      const timezone: string = String(transactionData.convertedDatetime.split(', ')[2]);
      moment.tz.setDefault(timezone);

      const parsedDate: moment.Moment = moment(datetime, 'MM/DD/YY hh:mma z');
      const transactionDatetimeISO: string = parsedDate.toISOString();
      const database: string = accountData.geotab_user.database;
      const sessionId: string = accountData.geotab_user.session_id;
      const userName: string = accountData.geotab_user.username;

      // Trip search from start of day to end to ensure no missing trips
      const startDateISO: string = parsedDate.clone().startOf('day').toISOString();
      const endDateISO: string = parsedDate.clone().endOf('day').toISOString();

      const authentication: {
        path: string | null,
        credentials: { database: string, sessionId: string, userName: string, },
      } = {
        credentials: {
          database,
          sessionId,
          userName
        },
        path: appState.path.value
      };

      // Initializing empty array so that if API fails the trip range will open
      let routes: any[] = [];
      try {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const GeotabApi = require('mg-api-js');
        const api = new GeotabApi(authentication);
        routes = await api.call('Get', {
          typeName: 'Trip',
          search: {
            fromDate: startDateISO,
            toDate: endDateISO,
            deviceSearch: {
              id: transactionData.vehicleId
            },
            includeOverlappedTrips: true
          }
        });
      } catch (error: any) {
        console.log('API Error: ', error);
      }

      let closestTrip: any = {};

      // Check which trips datetime range (start -> stop + stop duration) contains the transaction datetime
      if (routes.length) {
        for (let i: number = 0; i < routes.length; i++) {
          const stopDuration = routes[i].stopDuration;
          const hours: number = parseInt(stopDuration.split(':')[0], 10);
          const minutes: number = parseInt(stopDuration.split(':')[2], 10);
          const seconds: number = parseInt(stopDuration.split(':')[3], 10);
          const routeStart: moment.Moment = moment.tz(routes[i].start, timezone);
          const routeStopPlusDuration: moment.Moment = moment.tz(routes[i].stop, timezone)
            .add(hours, 'hour')
            .add(minutes, 'minute')
            .add(seconds, 'second');
          const transactionDatetime: moment.Moment = moment.tz(transactionDatetimeISO, timezone);
          const isInTheRange: boolean = transactionDatetime.isBetween(routeStart, routeStopPlusDuration);
          if (isInTheRange) {
            closestTrip = routes[i];
          }
        }
        if (Object.keys(closestTrip).length === 0) {
          // No trip was found during the comparisons
          openInNewTab(
            `https://${appState.path.value}/${database}/#tripsHistory,dateRange:(endDate:'${endDateISO}',startDate:'${startDateISO}'),devices:!(${transactionData.vehicleId})
          `);
        } else {
          // Trip found
          openInNewTab(
            `https://${appState.path.value}/${database}/#tripsHistory,dateRange:(endDate:'${endDateISO}',startDate:'${startDateISO}'),devices:!(${transactionData.vehicleId}),routes:(${transactionData.vehicleId}:!((start:'${closestTrip.start}',stop:'${closestTrip.stop}')))`
          );
        }
      } else {
        // No trip was found, routes array is empty
        openToast({
          type: 'error',
          label: 'Specific trip not found, opening trip history at time on transaction',
          autoClose: 3000,
          theme: 'dark'
        });
        setTimeout((): void => {
          openInNewTab(
            `https://${appState.path.value}/${database}/#tripsHistory,dateRange:(endDate:'${endDateISO}',startDate:'${startDateISO}'),devices:!(${transactionData.vehicleId})
            `);
        }, 3000);
      }
    } catch (error: any) {
      openToast({
        type: 'error',
        label: 'Error opening trip',
        autoClose: 2000,
        theme: 'dark'
      });
      console.log(error);
    }
  };

  const convertSeconds = (seconds: number | null): string | null => {
    if (!seconds) {
      return null;
    }
    const hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let timeString = '';
    if (hours > 0) {
      timeString += String(hours) + ':';
      if (minutes < 10) {
        timeString += '0';
      }
    }
    timeString += String(minutes) + ':';
    timeString += seconds < 10 ? '0' + String(seconds) : String(seconds);
    return timeString;
  };

  return (
    <>
      <div>
        <button
          type="button"
          className="transition text-custom-blue-normal hover:text-custom-blue-hover outline-none"
          onClick={() => {
            setIsShown(true);
          }}
        >
          {!isValidCell(transactionData?.uniqueId) ? 'N/A' : transactionData?.uniqueId}
        </button>
        {loading && <Box sx={{ width: '100%' }}>
          <LinearProgress/>
        </Box>}
      </div>
      {isShown && isDataFetched && (
        <Modal
          header={
            <div className="flex items-center gap-x-2">
              {iconName &&
                <Icon
                  name={iconName}
                  size="lg"
                />}
              <p>{t('Transaction Details')}</p>
            </div>
          }
          show={isShown}
          onHide={() => {
            setIsShown(false);
            setClickable(false);
            setGasStationX(gasStationX);
            setGasStationY(gasStationY);
          }}
          maxWidth={1200}
        >
          <div className="mt-4">
            <div className="grid grid-flow-col justify-around gap-x-0">
              <div className="flex gap-x-10">
                <div className="flex flex-col gap-y-7 text-sm">
                  {[
                    { id: 1, label: `${t('Unique ID')}`, value: isValidCell(transactionData.uniqueId) ? transactionData.uniqueId : 'N/A' },
                    { id: 2, label: `${t('Vehicle Name')}`, value: isValidCell(transactionData.vehicleName) ? transactionData.vehicleName : 'N/A' },
                    { id: 3, label: `${t('Vehicle Group')}`, value: renderVehicleGroups() ?? 'N/A' },
                    {
                      id: 4,
                      label: `${t('Driver Name')}`,
                      value: isValidCell(transactionData.driverName) ? transactionData.driverName : 'N/A'
                    },
                    {
                      id: 5,
                      label: `${t('Filling Time')}`,
                      value: convertSeconds(transactionData.fillingTime) ?? 'N/A'
                    },
                    {
                      id: 6,
                      label: `${t('Filling %')}`,
                      value: transactionData.preFillingFuelPercent !== null && transactionData.preFillingFuelPercent !== undefined
                        ? transactionData.preFillingFuelPercent
                        : 'N/A'
                    },
                    {
                      id: 7,
                      label: `${t('Fuel Unaccounted For')}`,
                      value: transactionData.fuelLevelDifference === '0'
                        ? 0
                        : transactionData.fuelLevelDifference
                          ? (`${transactionData.fuelLevelDifference}` + ' ' + (userSettings.unit_of_measure === 'metric'
                              ? 'Liters'
                              : 'Gallons'))
                          : 'N/A'
                    }
                  ].map((item) => (
                    <div
                      key={`item-${item.id}`}
                      className="text-custom-gray-main"
                    >
                      <div>{item.label}</div>
                      {
                        isLabelMatched(item.label, transactionData.type, transactionData.subtypes)
                          ? <p className="font-semibold text-error-3 break-all">{item.value}</p>
                          : <p className="font-semibold break-all">{item.value}</p>
                      }
                    </div>
                  ))}
                </div>
                <div className="flex flex-col gap-y-6 text-sm">
                  {[
                    { id: 8, label: `${t('Total Cost')}`, value: `${transactionData.totalCost}` || 'N/A' },
                    {
                      id: 9,
                      label: <div className="flex cursor-pointer gap-x-1">
                        {t('Datetime')} <DateTimeToolTip
                        transactionData={transactionData}
                        vehicleLocation={vehicleLocation}
                        />
                      </div>,
                      value: String(transactionData.convertedDatetime.split(', ')[0]) +
                        ' ' + String(transactionData.convertedDatetime.split(', ')[1]) +
                        ' ' + (String(transactionData.convertedDatetime.split(', ')[2]) === 'America/Denver' ? 'MST' : String(transactionData.convertedDatetime.split(', ')[2])) || 'N/A'
                    },
                    {
                      id: 10,
                      label: `${t('Fuel Type')}`,
                      value: transactionData.fuelType ?? 'N/A'
                    },
                    {
                      id: 11,
                      label: userSettings.unit_of_measure === 'metric'
                        ? `${t('Liters')}`
                        : `${t('Gallons')}`,
                      value: transactionData.numUnits !== null && transactionData.numUnits !== undefined
                        ? parseFloat(transactionData.numUnits).toFixed(2)
                        : 'N/A'
                    },
                    {
                      id: 12,
                      label: `${t('Price Per')} ${
                        userSettings.unit_of_measure === 'metric'
                          ? 'Liter'
                          : 'Gallon'
                      }`,
                      value: `$${transactionData.costPerUnit}` ?? 'N/A'
                    },
                    {
                      id: 13,
                      label: `${t('Distance')}`,
                      value: transactionData.distance
                        ? `${transactionData.distance ?? transactionData.distance === 0
                        ? parseFloat(transactionData.distance).toFixed(2)
                        : 'N/A'}` + ' ' + (userSettings.unit_of_measure === 'metric'
                        ? `${t('Kilometers')}`
                        : `${t('Miles')}`)
                        : 'N/A'
                    }
                  ].map((item) => (
                    <div
                      key={`item-${item.id}`}
                      className="text-custom-gray-main"
                    >
                      <div>{item.label}</div>
                      {
                        isLabelMatched(item.label, transactionData.type, transactionData.subtypes)
                          ? <p className="font-semibold text-error-3 break-all">{item.value}</p>
                          : <p className="font-semibold break-all">{item.value}</p>
                      }
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-px min-w-[1px] bg-dark-2"></div>
              <div
                className="flex flex-col gap-y-6 text-sm min-w-[450px] min-h-[400px] px-3"
                style={{
                  maxWidth: 262
                }}
              >
                {vehicleLocation &&
                  <TransactionLocation
                      gasStationX={gasStationX}
                      setGasStationX={setGasStationX}
                      setGasStationY={setGasStationY}
                      gasStationY={gasStationY}
                      vehicleLocation={vehicleLocation}
                      clickable={clickable}
                  />}
                <>
                  <div className="text-custom-gray-main whitespace-nowrap">
                    <p>{t('Name')}</p>
                    <p className="font-semibold">{gasStation?.name}</p>
                  </div>
                  <div className="text-custom-gray-main">
                    <p>{t('Address')}</p>
                    <p
                      className="font-semibold"
                    >{`${gasStation?.address}, ${gasStation?.city}, ${gasStation?.state} ${gasStation?.zip}`}</p>
                  </div>
                </>
              </div>
              <div className="w-px min-w-[1px] bg-dark-2"></div>
              <div className="flex flex-col items-start gap-y-4">
                <div className="flex gap-2">
                  <Button
                    className="!mt-0 !px-4 flex items-center gap-x-1"
                    type="button"
                    onClick={(): void => {
                      void openTrip();
                    }}
                  >
                    <Icon name="street-view"/>
                    {t('View Trip')}
                  </Button>
                </div>
                <Button
                  className="!mt-0 !px-4 flex items-center gap-x-1"
                  type="button"
                  onClick={(): void => {
                    const db = accountData.geotab_user.database;
                    const startDate = new Date(transactionData.datetime.slice(0, -11)).toISOString();
                    const date = new Date(transactionData.datetime.slice(0, -11));
                    date.setDate(date.getDate() + 1);
                    const endDate = date.toISOString();
                    openInNewTab(`https://${appState.path.value}/${db}/#engineMeasurements,dateRange:(endDate:'${endDate}',startDate:'${startDate}'),devices:!(${transactionData.vehicleId}),diagnostics:!(DiagnosticFuelLevelId),groupBy:diagnostic,includeHistoricData:!f`);
                  }}
                >
                  <Icon name="gas-pump"/>
                  {t('View Fuel Level')}
                </Button>
                {!group
                  ? <DismissTransaction
                    transactionData={transactionData}
                    removedDismissedTransaction={removedDismissedTransaction}
                    accountSettings={accountSettings}
                  />
                  : null}
                {isShown &&
                  <TransactionDetailsShareModal
                    transactionData={transactionData}
                    callback={(state: boolean) => {
                      setIsShown(state);
                    }}
                  />}
                {showIfta
                  ? <ReassignTransaction
                    vehicleId={transactionData.vehicleId}
                    currentTransactionId={transactionId}
                    currentTransactionGroupId={transactionData.transactionGroupId}
                    currentTransactionKind={group ? 'transactionGroup' : 'transaction'}
                    setTransactionDetailsData={setTransactionData}
                    timestamp={transactionData.timestamp}
                    removedDismissedTransaction={removedDismissedTransaction}
                  />
                  : <></>}
                <TransactionLocationUpdate
                  clickable={clickable}
                  setClickable={setClickable}
                  updateGasStationLocation={updateGasStationLocation}
                />
              </div>
            </div>
          </div>
        </Modal>)}
    </>
  );
};

export default TransactionDetailsModal;
