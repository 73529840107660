import React, { useState, useEffect } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import { DataGrid, type GridColDef, type GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { CircularProgress } from '@mui/material';
import { openToast } from '../../../../helpers/toast';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserEditorModal from '../user/UserEditor.modal';
import IconButton from '../../IconButton';
import { defaultUser, type User, type GeotabUser } from '../../../../routes/Authorized/Users/UserTypes';
import GeotabUserEditorModal from '../GeotabUserEditor.modal';
import UserSettingsEditorModal from '../user/UserSettingsEditor.modal';
import { updateUsers } from '../../../../helpers/users';

interface Props {
  accountRow: GridRenderCellParams;
}

const theme = createTheme({
  palette: {
    mode: 'dark'
  }
});

const ManageUsersModal: React.FC<Props> = ({ accountRow }) => {
  const [account, setAccount] = useState<any | null>(null);
  const [isShown, setIsShown] = useState<boolean>(false);
  const [showUserSettingsModal, setShowUserSettingsModal] = useState<boolean>(false);
  const [showUserEditorModal, setShowUserEditorModal] = useState<boolean>(false);
  const [showGeotabUserModal, setShowGeotabUserModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGeotabUsers, setLoadingGeotabUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [user, setUser] = useState<User>(defaultUser);
  const [geotabUser, setGeotabUser] = useState<GeotabUser | null>(null);
  const [geotabUsers, setGeotabUsers] = useState<GeotabUser[]>([]);
  const [database, setDatabase] = useState<string | null>(null);
  const onAddUser = (): void => {
    setUser({
      accountId: accountRow.row.id,
      email: null,
      geotabUsername: null,
      geotabUserId: null,
      id: null,
      name: null,
      role: 'user'
    });
  };

  const transformData = (Users: any): User[] => {
    return Users.map((user: any): User => {
      return {
        accountId: user.account_id,
        geotabUsername: user?.geotab_user?.username,
        geotabUserId: user?.geotab_user?.id,
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role
      };
    });
  };

  const fetchAccount = async (): Promise<void> => {
    try {
      const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${accountRow.row.id}`;
      const response = await axios.get(url);
      setAccount(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async (): Promise<void> => {
    setLoading(true);
    try {
      const url: string = `${process.env.REACT_APP_API_URL}/api/users-minimized/${accountRow.row.id}`;
      const response = await axios.get(url);
      if (response.data.data) {
        setUsers(transformData(response.data.data));
        for (let i: number = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].geotab_user) {
            setDatabase(response.data.data[i].geotab_user.database);
          }
        }
      }
    } catch (error: any) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchGeotabUsers = async (): Promise<void> => {
    setLoadingGeotabUsers(true);
    let primaryUserId: string | null = null;
    let geotabUserId: string | null = null;
    try {
      const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${accountRow.row.id}`;
      const accountResponse = await axios.get(url);
      if (accountResponse.data.data) {
        primaryUserId = accountResponse.data.data.primary_user_id;
        geotabUserId = accountResponse.data.data.geotab_user_id;
      }
    } catch (error) {
      console.log(error);
    }
    if (!primaryUserId) {
      openToast({
        type: 'error',
        label: 'This account does not have a primary user',
        autoClose: 5000,
        theme: 'dark'
      });
      setLoadingGeotabUsers(false);
      return;
    }
    if (!geotabUserId) {
      openToast({
        type: 'error',
        label: 'This account does not have a Geotab service account',
        autoClose: 5000,
        theme: 'dark'
      });
      setLoadingGeotabUsers(false);
      return;
    }
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/geotab-users`;
      const geotabUserparams = {
        user_id: primaryUserId,
        account_id: accountRow.row.id
      };
      const geotabUsersResponse = await axios.post(url, geotabUserparams);
      if (geotabUsersResponse) {
        setGeotabUsers(geotabUsersResponse.data.mygeotabUsers);
      } else {
        setGeotabUsers([]);
      }
    } catch (error) {
      console.log('Error fetching geotab users: ', error);
    }
    setLoadingGeotabUsers(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'userSettings',
      headerName: '',
      width: 40,
      renderCell: (params: GridRenderCellParams) => (
                <div className="flex gap-x-2">
                    <button
                        type="button"
                        className="transition text-custom-blue-normal hover:text-custom-blue-hover"
                        onClick={(): void => {
                          setShowUserSettingsModal(true);
                          setUser(params.row);
                        }}
                    >
                        <Icon name="gear"/>
                    </button>
                    <button
                        type="button"
                        className="transition text-custom-blue-normal hover:text-custom-blue-hover"
                        onClick={(): void => {
                          setUser(params.row);
                          setShowUserEditorModal(true);
                        }}
                    >
                        <Icon name="edit"/>
                    </button>
                </div>
      )
    },
    { field: 'name', headerName: t('Name'), width: 150 },
    { field: 'email', headerName: t('Email Address'), width: 200 },
    {
      field: 'geotabUsername',
      headerName: 'Geotab User',
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return (
                    <>
                        {params.row.geotabUsername && (
                            <button
                                onClick={(): void => {
                                  setShowGeotabUserModal(true);
                                  setGeotabUser({
                                    accountId: params.row.accountId,
                                    database,
                                    geotabUsername: params.row.geotabUsername,
                                    hasGeotabUser: true,
                                    id: params.row.geotabUserId,
                                    userId: params.row.id
                                  });
                                }}
                                className="text-custom-blue-normal"
                            >
                                {params.row.geotabUsername}
                            </button>
                        )}
                        {!params.row.geotabUsername && (
                            <button
                                type="button"
                                className={'transition border border-custom-blue-normal text-custom-blue-normal' +
                                    ' hover:bg-custom-blue-normal hover:text-white rounded-full text-xs px-4 py-1'}
                                onClick={(): void => {
                                  setShowGeotabUserModal(true);
                                  setGeotabUser({
                                    accountId: params.row.accountId,
                                    database,
                                    geotabUsername: null,
                                    hasGeotabUser: false,
                                    id: null,
                                    userId: params.row.id
                                  });
                                }}
                            >
                                {t('Add Geotab User')}
                            </button>
                        )}
                    </>
        );
      }
    }
  ];

  const onHide = (): void => {
    setIsShown(false);
  };
  const onHideUserEditorModal = (): void => {
    setShowUserEditorModal(false);
  };

  const onHideGeotabUserEditorModal = (): void => {
    setShowGeotabUserModal(false);
  };
  useEffect((): void => {
    if (isShown) {
      void fetchAccount();
    }
  }, [isShown]);

  return (
        <>
            <button
                style={{ margin: '0' }}
                className="transition text-custom-blue-normal hover:text-custom-blue-hover outline-none"
                onClick={() => {
                  void fetchUsers();
                  void fetchGeotabUsers();
                  setIsShown(true);
                }}
                type="button"
            >
                {t('Show')}
            </button>
            {isShown && <Modal
                header={(
                    <div className="flex items-center justify-between mb-3 pr-4">
                        <div className="flex items-center gap-x-2 text-custom-gray-light">
                            <Icon name="gear"/>
                            <span>{t('Users')}</span>
                        </div>
                        <div className="flex items-center gap-x-3">
                            <IconButton
                                icon="plus"
                                onClick={() => {
                                  onAddUser();
                                  setShowUserEditorModal(true);
                                }}
                            />
                        </div>
                    </div>
                )}
                onHide={onHide}
                show={isShown}
                maxWidth={'75rem'}
            >
                <div className="text-xs flex flex-col gap-2 px-4">
                    <p>{`${t('This account must have a primary user and a Geotab service account assigned' +
                        ' to it. If you have no users you must create one and assign it as the primary user in the' +
                        ' dashboard.')}`}</p>
                    <p>{`${t('You can add a Geotab service account in the update service account modal.')}`}</p>
                    <p>{`${t('You are not able to delete the primary user of an account, you must first reassign to another' +
                        ' user in if you want to delete the desired user.')}`}</p>
                </div>
                {loading && (
                    <div
                        className={'p-4 rounded-xl absolute w-full h-full flex justify-center loading items-center bg-opacity-50' +
                            ' bg-black'}
                    >
                        <CircularProgress/>
                    </div>
                )}
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <DataGrid
                        hideFooter={true}
                        localeText={{
                          noRowsLabel: t('No rows'),
                          toolbarColumns: t('Columns'),
                          toolbarColumnsLabel: t('Columns'),
                          toolbarDensity: t('Density'),
                          toolbarDensityLabel: t('Density'),
                          toolbarDensityCompact: t('Compact'),
                          toolbarDensityStandard: t('Standard'),
                          toolbarDensityComfortable: t('Comfortable'),
                          toolbarQuickFilterPlaceholder: `${t('Search')}...`,
                          MuiTablePagination: {
                            labelRowsPerPage: t('Rows per Page'),
                            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
                          }
                        }}
                        rows={[...users].sort((userA: User, userB: User): number => {
                          const keyA: string = userA.name ? userA.name.toLowerCase() : '';
                          const keyB: string = userB.name ? userB.name.toLowerCase() : '';
                          if (keyA < keyB) return -1;
                          if (keyA > keyB) return 1;
                          return 0;
                        })}
                        columns={columns}
                        disableColumnFilter
                        disableColumnMenu
                        disableRowSelectionOnClick
                        density="compact"
                        disableDensitySelector
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: { // Disable Export Option
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true }
                          }
                        }}
                        className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
                        sx={{
                          height: '25rem',
                          '& .MuiDataGrid-row': {
                            borderRadius: '80px',
                            backgroundColor: '#242838',
                            marginTop: '4px',
                            borderBottom: 'none'
                          },
                          '& .MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          '& .MuiDataGrid-cell': {
                            border: 'none'
                          },
                          '& .MuiDataGrid-columnHeaders': {
                            borderRadius: '80px',
                            backgroundColor: '#242838',
                            borderBottom: 'none',
                            marginBottom: '10px'
                          },
                          border: 0
                        }}
                    />
                </ThemeProvider>
            </Modal>}
            {showUserEditorModal && account && user &&
                <UserEditorModal
                    account={account}
                    user={user}
                    show={true}
                    onHide={onHideUserEditorModal}
                    updateUsers={(user: User, method: 'update' | 'remove' | 'create') => {
                      updateUsers(user, method, setUsers);
                    }}
                />}
            {showUserSettingsModal && user &&
                <UserSettingsEditorModal
                    user={user}
                    show={true}
                    onHide={() => {
                      setShowUserSettingsModal(false);
                    }}
                />}
            {showGeotabUserModal && geotabUser && geotabUsers &&
                <GeotabUserEditorModal
                    geotabUser={geotabUser}
                    geotabUsers={geotabUsers}
                    updateUsers={(user: User, method: 'update' | 'remove' | 'create' | 'geotabUser') => {
                      updateUsers(user, method, setUsers);
                    }}
                    show={true}
                    onHide={onHideGeotabUserEditorModal}
                    loadingGeotabUsers={loadingGeotabUsers}
                />}
        </>
  );
};

export default withTranslation()(ManageUsersModal);
