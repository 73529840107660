import React, { useEffect, useState } from 'react';
import IconButton from '../IconButton';
import Modal from '../Modal';
import Icon from '../Icon';
import { Formik } from 'formik';
import Button from '../Button';
import Checkbox from '../Formik/Checkbox';
import { iconColorMap, iconTypeMapping } from '../../../helpers/types';
import axios, { AxiosError } from 'axios';
import { appState } from '../../../index';
import { t } from 'i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { openToast } from '../../../helpers/toast';
import { type AccountSettings } from '../../../types/account';
import { type UserSettings } from '../../../types/user';

interface PaginationModel {
  page: number;
  pageSize: number;
}

interface Props {
  show: boolean;
  onHide: () => void;
  openModal: () => void;
  fetchTransactions: (paginationModel?: PaginationModel) => Promise<void>;
}

const TransactionsAlertModal: React.FC<Props> = ({
  show,
  onHide,
  openModal,
  fetchTransactions
}) => {
  const [accountSettings, setAccountSettings] = useState<AccountSettings | null>(null);
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);

  useEffect((): void => {
    if (show) {
      void fetchAccountSettings();
    }
  }, [show]);
  const fetchAccountSettings = async (): Promise<void> => {
    try {
      const accountSettingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/account-settings?filter=account_id:${appState.accountId.value}(eq)`);
      if (!accountSettingsResponse.data.data.length) {
        return;
      }
      setAccountSettings(accountSettingsResponse.data.data[0]);
      const userSettingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-settings?filter=user_id:${appState.userId.value}(eq)`);
      if (!userSettingsResponse.data.data.length) {
        return;
      }
      setUserSettings(userSettingsResponse.data.data[0]);
    } catch (error) {
      console.error('Error fetching account settings:', error);
    }
  };
  const updateAlertSettings = async (settingsSubmitted: any): Promise<void> => {
    try {
      if (!settingsSubmitted) {
        return;
      }
      if (!userSettings || !accountSettings) {
        return;
      }
      axios.put(`${process.env.REACT_APP_API_URL}/api/user-settings/${userSettings.id}`, settingsSubmitted).then(() => {
        fetchTransactions().then(() => {
          onHide();
        });
      });
    } catch (error: AxiosError | any) {
      console.error('Error updating user settings: ', error);
      let message = 'Error updating alert settings';
      if (error instanceof AxiosError) {
        message = error?.response?.data.message;
      }
      openToast({
        type: 'error',
        label: t(message),
        autoClose: 2000,
        theme: 'dark'
      });
      onHide();
    }
  };

  return (
    <>
      <IconButton
        icon="warning"
        onClick={() => {
          openModal();
        }}
      />
      <Modal
        header={
          <div className="flex items-center gap-x-2">
            <Icon name="warning"/>
            <p>{t('User Alerts')}</p>
          </div>
        }
        show={show}
        onHide={onHide}
      >
        {(<Formik
          enableReinitialize
          initialValues={{
            bt_alert_settings: userSettings?.bt_alert_settings
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await updateAlertSettings(values);
            setSubmitting(false);
            onHide();
          }}
        >
          {(props: any) => {
            return (
              <form
                onSubmit={props.handleSubmit}
                className="mt-4"
              >
                {props.isSubmitting && (<div className="loading-overlay"><CircularProgress/></div>)}
                <div className="flex flex-col gap-y-2 mt-6">
                  <Checkbox
                    switchType
                    name="bt_alert_settings.no_vehicle_location"
                    label={
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.no_vehicle_location]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.no_vehicle_location}/>
                        </div>
                        <p>{t('No Vehicle Location')}</p>
                      </div>
                    }
                  />
                  <Checkbox
                    switchType
                    name="bt_alert_settings.location_mismatch"
                    label={
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.location_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.location_mismatch}/>
                        </div>
                        <p>{t('Location Mismatch')}</p>
                      </div>
                    }
                  />
                  <Checkbox
                    switchType
                    name="bt_alert_settings.fuel_level_mismatch"
                    label={
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.fuel_level_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.fuel_level_mismatch}/>
                        </div>
                        <p>{t('Fuel Level Mismatch')}</p>
                      </div>
                    }
                  />
                  <Checkbox
                    switchType
                    name="bt_alert_settings.fuel_type_mismatch"
                    label={
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.fuel_type_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.fuel_type_mismatch}/>
                        </div>
                        <p>{t('Fuel Type Mismatch')}</p>
                      </div>
                    }
                  />
                  <Checkbox
                    switchType
                    name="bt_alert_settings.filling_time_alert"
                    label={
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.filling_time_alert]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.filling_time_alert}/>
                        </div>
                        <p>{t('Filling Time Alert')}</p>
                      </div>
                    }
                  />
                  <Checkbox
                    switchType
                    name="bt_alert_settings.filling_percent_alert"
                    label={
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.filling_percent_alert]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.filling_percent_alert}/>
                        </div>
                        <p>{t('Filling Percent Alert')}</p>
                      </div>
                    }
                  />
                </div>
                <Button
                  disabled={props.isSubmitting}
                  className="!px-12"
                >
                  {t('Submit')}
                </Button>
              </form>
            );
          }}
        </Formik>)}
      </Modal>
    </>
  );
};

export default TransactionsAlertModal;
